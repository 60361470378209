import { PotencyUnit } from 'src/../types/graphql';

export enum PotencyUnitDisplayString {
  milligrams = 'mg',
  milligramsPerGram = 'mg/g',
  percentage = '%',
}

export const UNIT_OPTIONS = [
  PotencyUnitDisplayString.percentage,
  PotencyUnitDisplayString.milligrams,
  PotencyUnitDisplayString.milligramsPerGram,
].map((unit) => ({
  key: unit,
  label: unit,
  value: unit,
}));

export const PotencyUnitByDisplayString = new Map<PotencyUnitDisplayString, PotencyUnit>([
  [PotencyUnitDisplayString.percentage, PotencyUnit.percentage],
  [PotencyUnitDisplayString.milligrams, PotencyUnit.milligrams],
  [PotencyUnitDisplayString.milligramsPerGram, PotencyUnit.milligramsPerGram],
]);

export const PotencyDisplayStringByUnit = new Map<PotencyUnit, PotencyUnitDisplayString>([
  [PotencyUnit.percentage, PotencyUnitDisplayString.percentage],
  [PotencyUnit.milligrams, PotencyUnitDisplayString.milligrams],
  [PotencyUnit.milligramsPerGram, PotencyUnitDisplayString.milligramsPerGram],
]);

export const UNIT_SYMBOLS = {
  [PotencyUnit.percentage]: '%',
  [PotencyUnit.milligrams]: 'mg',
  [PotencyUnit.milligramsPerGram]: 'mg/g',
  [PotencyUnit.milligramsPerMl]: 'mg/mL',
};
