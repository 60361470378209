import { useCallback } from 'react';
import type { BaseSchema, ValidationError } from 'yup';

type ValidationResults<T = any> = {
  values: T;
  errors: {
    [K in keyof T]: { type: string; message: string };
  };
};

type YupValidationResolver<T = any> = (data: T) => Promise<ValidationResults>;

export function useYupValidationResolver<T = any>(validationSchema: BaseSchema): YupValidationResolver<T> {
  return useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: ValidationError[], currentError: ValidationError) => ({
              ...allErrors,
              [currentError.path ?? 'test']: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
}
