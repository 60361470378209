/* eslint-disable max-len, @typescript-eslint/ban-ts-comment */
import React from 'react';
import { colors } from 'src/shared/themes';

type IconProps = {
  color?: string;
  height?: string;
  className?: string;
};

export function DutchieLogo({ color = 'black', ...restOfProps }: IconProps): JSX.Element {
  const fillColor: string = (colors as Record<string, any>)[color] ?? color;

  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 30 30'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
      {...restOfProps}
    >
      <title>Dutchie Logo</title>
      <path d='M26.9951 21.0931C25.5071 21.8988 23.9188 22.5033 22.2718 22.8907C20.4782 23.3024 18.6328 23.4413 16.7978 23.3026C13.7162 23.0378 10.7368 21.6213 8.67355 19.2984C8.42142 19.0182 8.21691 18.6749 7.82471 18.5811C7.20279 18.441 6.76436 18.8613 6.61168 19.4413C5.1045 25.0819 10.255 30.4381 15.9083 29.9716C18.4296 29.8093 20.8692 29.0136 23.0015 27.6581C25.1338 26.3026 26.8899 24.4311 28.1072 22.2168C28.1783 22.0931 28.2149 21.9527 28.2135 21.81C28.212 21.6674 28.1724 21.5278 28.0988 21.4056C27.9918 21.2199 27.8166 21.0834 27.6104 21.025C27.4043 20.9667 27.1835 20.9911 26.9951 21.0931Z' />
      <path d='M14.2877 1.5331C15.7306 2.41862 17.0494 3.49187 18.2098 4.72469C19.4589 6.07305 20.4977 7.60194 21.2914 9.25988C22.6025 12.062 22.8672 15.3502 21.8839 18.2939C21.7648 18.6525 21.5715 18.9944 21.6864 19.3867C21.8783 19.9961 22.4624 20.1629 23.0381 20.0087C28.6732 18.5054 30.7421 11.3615 27.5106 6.69877C26.11 4.59499 24.2015 2.87853 21.9617 1.70819C19.7219 0.537839 17.2232 -0.0485907 14.6968 0.00314983C14.5531 0.00410092 14.4123 0.0436044 14.2891 0.117537C14.1659 0.19147 14.0648 0.297121 13.9964 0.423465C13.8905 0.609855 13.8617 0.830279 13.9161 1.03763C13.9706 1.24498 14.104 1.4228 14.2877 1.5331Z' />
      <path d='M15.6352 10.6917C14.6417 10.5448 13.6279 10.7469 12.7666 11.2636C11.9053 11.7804 11.2497 12.5798 10.9115 13.5257C10.5732 14.4716 10.5733 15.5056 10.9117 16.4514C11.25 17.3973 11.9057 18.1966 12.7671 18.7132C13.6285 19.2298 14.6423 19.4318 15.6358 19.2847C16.6294 19.1377 17.5412 18.6506 18.2161 17.9066C18.8909 17.1626 19.287 16.2076 19.337 15.2042C19.3869 14.2008 19.0876 13.2112 18.4899 12.4038C18.1509 11.9438 17.7241 11.5556 17.234 11.2617C16.744 10.9678 16.2006 10.7741 15.6352 10.6917Z' />
      <path d='M14.4109 7.36429C14.7807 7.28724 15.1799 7.28163 15.4559 6.98881C15.8873 6.51806 15.736 5.92822 15.3158 5.5079C11.206 1.37761 3.97969 3.15834 1.55783 8.28899C0.437448 10.5538 -0.0936996 13.0647 0.0135403 15.5894C0.12078 18.114 0.862943 20.5708 2.17135 22.7324C2.24327 22.8556 2.34672 22.9574 2.47105 23.0274C2.59538 23.0973 2.7361 23.1328 2.87872 23.1303C3.09317 23.1311 3.29939 23.0478 3.45312 22.8982C3.60684 22.7486 3.69582 22.5448 3.70095 22.3303C3.74758 20.6395 4.01794 18.9623 4.50496 17.3426C5.04584 15.5828 5.84858 13.9146 6.8862 12.3941C8.65532 9.85816 11.3713 7.98916 14.4109 7.36429Z' />
    </svg>
  );
}
