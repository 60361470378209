import React, { useState } from 'react';
import styled from 'styled-components';

import Sidebar from 'src/components/layout/sidebar';
import { mediaSizes } from 'src/shared/styles';
import { MobileHeader } from 'src/components/layout/mobile-header';

type Props = {
  children: React.ReactNode;
};

export function Layout({ children }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const closeDrawer = (): void => setIsOpen(false);

  return (
    <Container>
      <Sidebar isOpen={isOpen} closeDrawer={closeDrawer} />
      <Content>
        <Wrapper>
          <MobileWrapper>
            <MobileHeader openDrawer={() => setIsOpen(true)} />
          </MobileWrapper>
          {children}
        </Wrapper>
      </Content>
    </Container>
  );
}

export const Content = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
  min-width: 0;
  padding-left: 200px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    padding-left: 0px;
  }
`;

const Container = styled.div`
  display: flex;
`;

const MobileWrapper = styled.div`
  @media only screen and (min-width: ${mediaSizes.desktop + 2}px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 6;
  flex-direction: column;
`;
