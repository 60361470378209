import React from 'react';
import styled from 'styled-components';

type Props = {
  openDrawer: () => void;
};

export function MobileHeader(props: Props): JSX.Element {
  const { openDrawer } = props;

  return (
    <Header>
      <Container>
        <OpenMenuButton onClick={openDrawer} />
        <Divider />
        <img src='/icons/dutchie-wordmark-white.svg' alt='Dutchie Logo' style={{ width: 70 }} />
      </Container>
    </Header>
  );
}

const Header = styled.div`
  display: flex;
  width: 100vw;
  padding: 0 22px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.15;
  margin: 0 18px 0 20px;
`;

const OpenMenuButton = styled(({ ...props }) => <img src='/icons/menu-icon-white-v2.svg' {...props} />)`
  width: 24px;
  height: 17px;
  cursor: pointer;
`;
