import { path } from 'static-path';

// Due to https://github.com/garybernhardt/static-path/issues/6
function pathWithoutParams<Pattern extends string>(pathName: Pattern): () => string {
  const route = path(pathName);
  return () => route({} as any);
}

function pathWithOptionalParams<Pattern extends string>(pathName: Pattern, params: any): () => string {
  const route = path(pathName);
  return () => route(params);
}

export const AppRoutes = {
  brandCreate: pathWithoutParams('/brands/new'),
  brandEdit: path('/brands/edit/:id'),
  brands: pathWithoutParams('/brands'),
  forgotPassword: pathWithoutParams('/reset'),
  home: pathWithoutParams('/products'),
  archivedProducts: pathWithoutParams('/products/archived'),
  invitationPassword: pathWithOptionalParams('/invitation/:resetToken', { resetToken: '[resetToken]' }),
  login: pathWithoutParams('/login'),
  productCreate: pathWithoutParams('/products/new'),
  productEdit: path('/products/edit/:id'),
  products: pathWithoutParams('/products'),
  resetPassword: pathWithOptionalParams('/reset/:resetToken', { resetToken: '[resetToken]' }),
  settings: pathWithoutParams('/settings'),
  settingsUser: pathWithoutParams('/settings/users'),
  terms: pathWithoutParams('/terms'),
  ads: pathWithoutParams('/ads'),
};

export default AppRoutes;
