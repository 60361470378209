import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import apolloClient from 'src/utils/apollo';
import UserState from './user';
import { UIState } from './ui';

export type ChildStores = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  user: UserState;
  ui: UIState;
};

export class RootState {
  readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  readonly userStore: UserState;
  readonly uiStore: UIState;

  constructor() {
    this.apolloClient = apolloClient;
    this.userStore = new UserState(this);
    this.uiStore = new UIState(this);
  }

  get stores(): ChildStores {
    return {
      apolloClient: this.apolloClient,
      user: this.userStore,
      ui: this.uiStore,
    };
  }
}

export default new RootState();
