import { statesMap } from 'src/shared/constants';

export const stateOptions = [
  { key: 'all', label: 'All States', value: '' },
  ...Object.entries(statesMap as Record<string, string>).map(([abbreviation, state]) => ({
    key: abbreviation,
    value: abbreviation,
    label: state,
  })),
];

export const categoryOptions = [
  {
    key: 'all',
    label: 'All Categories',
    value: '',
  },
  {
    key: 'flower',
    label: 'Flower',
    value: 'Flower',
  },
  {
    key: 'pre-rolls',
    label: 'Pre-Rolls',
    value: 'Pre-Rolls',
  },
  {
    key: 'vaporizers',
    label: 'Vaporizers',
    value: 'Vaporizers',
  },
  {
    key: 'concentrates',
    label: 'Concentrates',
    value: 'Concentrate',
  },
  {
    key: 'edibles',
    label: 'Edibles',
    value: 'Edible',
  },
  {
    key: 'topicals',
    label: 'Topicals',
    value: 'Topicals',
  },
  {
    key: 'tinctures',
    label: 'Tinctures',
    value: 'Tincture',
  },
  {
    key: 'cbd',
    label: 'CBD',
    value: 'CBD',
  },
  {
    key: 'seeds',
    label: 'Seeds',
    value: 'Seeds',
  },
  {
    key: 'clones',
    label: 'Clones',
    value: 'Clones',
  },
  {
    key: 'accessories',
    label: 'Accessories',
    value: 'Accessories',
  },
  {
    key: 'apparel',
    label: 'Apparel',
    value: 'Apparel',
  },
  {
    key: 'n/a',
    label: 'N/A',
    value: 'N/A',
  },
];
