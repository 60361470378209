import React, { useState } from 'react';
import { observer } from 'mobx-react';
import isEmail from 'validator/lib/isEmail';
import styled from 'styled-components';
import { useStores } from 'src/state';

import { TextInput } from 'src/shared/components/forms/inputs/text-input';
import ModalContainer from 'src/shared/modals/container';
import ModalSecondaryHeader from 'src/shared/modals/header';
import ModalButton from 'src/shared/modals/button';
import ModalClose from 'src/shared/modals/close';
import ModalCopy from 'src/shared/modals/copy';
import { useTranslation } from 'next-i18next';

type ForgotPasswordModal = {
  onClose?: () => void;
};

function ForgotPasswordForm(props: ForgotPasswordModal): JSX.Element {
  const { onClose } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { ui, user } = useStores();
  const { t } = useTranslation();

  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value);
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const validate = (): boolean => {
    const errorMessage = t('errors.user.invalidEmailAddress', 'Please enter a valid email address');

    if (!isEmail(email)) {
      ui.showErnie(errorMessage, 'danger');
      return false;
    }
    return true;
  };

  const handleSendBrandUserPasswordResetEmail = async (e: any): Promise<void> => {
    e.preventDefault();

    const errorMessage = t('errors.general.unknownError', 'Something went wrong. Please contact support.');

    const successMessage = t(
      'reset-password.success',
      'An email has been sent with instructions on how to reset your password.'
    );

    if (!validate()) {
      return;
    }

    setLoading(true);
    const result = await user.sendBrandUserPasswordResetEmail(email);
    setLoading(false);

    if (!result.success) {
      ui.showErnie(errorMessage, 'danger');
      console.error(result.err);
      return;
    }

    ui.showErnie(successMessage, 'success');
    setEmail('');
  };

  return (
    <ModalContainer
      isOpen
      onRequestClose={handleClose}
      data-cy='reset-password-modal'
      data-test='reset-password-modal'
      width={['100%', '100%', '100%', '453px']}
    >
      <ModalSecondaryHeader>{t('reset-password.heading', 'Reset Your Password')}</ModalSecondaryHeader>
      <ModalCopy maxWidth='354px'>
        {t(
          'reset-password.info_text',
          "Enter the email address associated with your account, and we'll email you a link to reset your password."
        )}
      </ModalCopy>
      <form onSubmit={handleSendBrandUserPasswordResetEmail}>
        <InputContainer>
          <StyledInput
            data-cy='reset-email-input'
            data-test='reset-email-input'
            name='email'
            onChange={updateEmail}
            value={email}
            placeholder='Email address'
            m={['5px auto', '5px auto', '5px auto', '0 18px 0 0']}
          />
          <ModalButton
            loading={loading}
            mt={[20, 20, 20, 0]}
            width={['100%', '100%', '100%', '100px']}
            buttonContainerWidth='auto'
            data-cy='reset-submit-button'
            data-test='reset-submit-button'
          >
            {t('reset_password.submit', 'Submit')}
          </ModalButton>
        </InputContainer>
      </form>
      {onClose && <ModalClose onClick={handleClose} />}
    </ModalContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  flexdirection: 'column';
  margin-top: 32px;
`;

const StyledInput = styled(TextInput)`
  text-align: center;
  width: 230px;
`;

export const ForgotPasswordFormModal = observer(ForgotPasswordForm);
export default ForgotPasswordFormModal;
