import { useStores } from 'src/state';
import { useGetBrandQuery, Maybe } from '../../types/graphql';

export const perPage = 50;

type UseUserBrandHook = () => {
  isLoading: boolean;
  brandImageUrl: Maybe<string> | undefined;
  brandName: Maybe<string> | undefined;
};
const useUserBrand: UseUserBrandHook = () => {
  const { apolloClient, user } = useStores();
  const brandId = user.profile?.parentBrandId ?? '';

  const { loading, data } = useGetBrandQuery({
    client: apolloClient,
    variables: { id: brandId },
  });

  const brandImageUrl = data?.getBrand?.imageUrl;
  const brandName = data?.getBrand?.name;

  return {
    isLoading: loading,
    brandImageUrl,
    brandName,
  };
};

export default useUserBrand;
