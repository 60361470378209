import React, { FocusEventHandler, ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { POTENCY_UNITS_DISPLAY } from 'src/shared/constants';
import { TextInput } from 'src/shared/components/forms/inputs/text-input';

type PotencyNumberInputProps = {
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  unit: string;
  value?: string;
  type?: string;
  width?: string;
  max?: number;
  min?: number;
  maxLength?: number;
  step?: number;
};

export function PotencyNumberInput(props: PotencyNumberInputProps): JSX.Element {
  const {
    onBlur,
    onChange,
    unit,
    value,
    type = 'number',
    width = '121px !important',
    maxLength,
    max,
    min,
    step,
  } = props;

  return (
    <Container>
      <NumberInput
        onBlur={onBlur}
        onChange={onChange}
        placeholder='0'
        type={type}
        value={value}
        width={width}
        maxLength={maxLength}
        max={max}
        min={min}
        step={step}
      />
      <PotencyIcon>{POTENCY_UNITS_DISPLAY[unit]}</PotencyIcon>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
`;

const NumberInput = styled(TextInput)`
  position: relative;
  border-radius: 3px;
  width: ${({ width }) => width};
  -moz-appearance: textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PotencyIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  line-height: 12px;
  font-size: 13px;
  font-weight: bold;
  color: rgb(143, 152, 158);
`;
