import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import styled from 'styled-components';
import { ModalContainer, ModalPrimaryHeader } from 'src/shared/modals';
import { Button } from 'src/shared/components/forms/inputs';
import { SmallLoader } from 'src/shared/components';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

const S3_UPLOAD_SIZE_LIMIT = 10;

function approxFileSizeInMbs(dataUrl: string): number {
  const dataUrlPrefix = 'data:image/png;base64,';
  return Math.round(((dataUrl.length - dataUrlPrefix.length) * 3) / 4) / 1024 / 1024;
}

export enum ImageModalErrorType {
  imageTooLarge = 'imageTooLarge',
}
export type ImageModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSave: (imageUrl: string) => void;
  src: string;
  onError: (type: string) => void;
  isUploading?: boolean;
  onDelete?: () => void;
};

export function ImageModal(props: ImageModalProps): JSX.Element {
  const { onSave, isOpen, onClose, onError, src, isUploading = false, onDelete } = props;

  const editorRef = useRef<Cropper | null>(null);
  const [rotation, setRotation] = useState(0);

  function handleClickSave(): void {
    if (!editorRef.current) {
      return;
    }
    const imageUrl = editorRef.current.getCroppedCanvas({ fillColor: '#fff' }).toDataURL('image/jpeg');
    const fileSize = approxFileSizeInMbs(imageUrl);
    if (fileSize > S3_UPLOAD_SIZE_LIMIT) {
      onError(ImageModalErrorType.imageTooLarge);
      return;
    }
    onSave(imageUrl);
  }

  function handleRotate(delta: number): void {
    setRotation((prevRotation) => prevRotation + delta);
  }

  function handleRotateClockwise(): void {
    handleRotate(90);
  }

  function handleRotateCounterClockwise(): void {
    handleRotate(-90);
  }

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={onClose} width='494px' fancy alignItems='flex-start' pt='90px'>
      <ModalPrimaryHeader copy='Crop Image' close={onClose} />
      <Cropper
        ref={editorRef}
        src={src}
        style={{ width: '100%', height: 'calc(50vh)' }}
        aspectRatio={319 / 227}
        guides={false}
        // rotateTo isn't listed as a prop
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rotateTo={rotation}
      />

      <Container>
        <EditLabel className='editLabel' style={{ width: '100px' }}>
          Rotate:
        </EditLabel>

        <span>
          <LinkLikeButton
            className='edit-product-link'
            onClick={handleRotateClockwise}
            role='button'
            // @ts-expect-error tabIndex on span 🙈
            tabIndex='0'
          >
            Clockwise
          </LinkLikeButton>
          &nbsp; | &nbsp;
          <LinkLikeButton
            className='edit-product-link'
            onClick={handleRotateCounterClockwise}
            role='button'
            // @ts-expect-error tabIndex on span 🙈
            tabIndex='0'
          >
            Counter-Clockwise
          </LinkLikeButton>
        </span>
      </Container>
      <FooterContainer>
        <Button width='118px' onClick={handleClickSave}>
          {isUploading ? <SmallLoader height={20} /> : 'Save'}
        </Button>
        {onDelete && <DeleteImageLink onClick={onDelete}>Delete Image</DeleteImageLink>}
      </FooterContainer>
    </ModalContainer>
  );
}

export default ImageModal;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DeleteImageLink = styled.span`
  font-size: 13px;
  color: #f3583a;
  margin-left: auto;
  cursor: pointer;
`;

const EditLabel = styled.span`
  width: 75px;
  margin-bottom: 20px;
  margin-top: 15px;
  display: inline-block;
  color: #707478;
  font-size: 14px;
  font-weight: bold;
`;

const LinkLikeButton = styled.span`
  color: #589cd9;
  margin-top: 3px;
  cursor: pointer;
  font-size: 13px;
`;

const Container = styled.div`
  text-align: left;
`;
