import { useTranslation } from 'next-i18next';
import { AppRoutes } from 'src/utils/constants';

import { useStores } from 'src/state';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { NavLink } from 'src/utils/types/nav-link';
import useSuperAdminOrBrandAdmin from './use-super-admin-or-brand-admin';

// following admin pattern - load links as hook in case we need to hide/show based on role
export function useSidebarLinks(): NavLink[] {
  const { t } = useTranslation();
  const isSuperAdminOrBrandAdmin = useSuperAdminOrBrandAdmin();
  const { _user } = useStores().user;
  const flags = useFlags();

  const isCampaignManagerEnabled = flags['growth.ecomm.ads.brand-portal-campaign-manager'];
  const isAdsUser = _user?.profile.permissions?.manageAdCampaigns;

  return [
    {
      visible: true,
      icon: 'promote',
      href: AppRoutes.products(),
      label: t('products.heading', 'Products'),
      subHeaderLinks: [
        {
          visible: isSuperAdminOrBrandAdmin,
          href: AppRoutes.products(),
          label: t('products.subHeading', 'Active Products'),
        },
        {
          visible: isSuperAdminOrBrandAdmin,
          href: AppRoutes.archivedProducts(),
          label: t('archivedProducts.heading', 'Archived Products'),
        },
      ],
    },
    { visible: true, icon: 'dispensaries', href: AppRoutes.brands(), label: t('brands.heading', 'Brands') },
    {
      visible: isCampaignManagerEnabled && isAdsUser,
      icon: 'ads',
      href: AppRoutes.ads(),
      label: t('ads.heading', 'Ads'),
      new: true,
    },
    {
      visible: true,
      icon: 'settings',
      href: AppRoutes.settings(),
      label: t('settings.heading', 'Settings'),
      subHeaderLinks: [
        { visible: isSuperAdminOrBrandAdmin, href: AppRoutes.settings(), label: t('account.heading', 'My Account') },
        { visible: isSuperAdminOrBrandAdmin, href: AppRoutes.settingsUser(), label: t('users.heading', 'Users') },
      ],
    },
  ];
}
