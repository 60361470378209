import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { SidebarIcon } from 'src/components/layout/sidebar-icon';
import type { NavLink } from 'src/utils/types/nav-link';

type Props = {
  link: NavLink;
  onClick: () => unknown;
};

export function SidebarLink(props: Props): JSX.Element {
  const {
    link: { icon, label, href, new: isNew },
  } = props;
  const router = useRouter();

  const handleClick: () => void = () => {
    void props.onClick();
    void router.push(href);
  };

  const isActive = router.pathname.includes(href);

  return (
    <StyledLink active={!!isActive} onClick={handleClick}>
      {icon && <SidebarIcon icon={icon} active={isActive} />}
      <Label>{label}</Label>
      {isNew && <NewLabel>NEW</NewLabel>}
    </StyledLink>
  );
}

const Label = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  margin-left: 18px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    font-size: 18px;
  }
`;

const StyledLink = styled.div<{ active: boolean }>`
  align-items: center;
  display: flex;
  line-height: 42px;
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ active }) => (active ? '1' : '0.7')};
  cursor: pointer;
  padding-left: 38px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    line-height: 65px;
    padding-left: 56px;
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQueries.largePhone} {
    line-height: 54px;
    padding-left: 55px;
    margin: 5px 0;
  }

  &:hover {
    opacity: 1;
    text-decoration: none !important;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${({ theme }) => theme.colors.darkPrimary};
      opacity: 1;
    `}
`;

const NewLabel = styled.span`
  background: #ffd3b8;
  color: #121516;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  margin-left: 6px;
`;
