import React from 'react';
import styled from 'styled-components';

/**
 * @param {String} name
 * @param {String} value "yes", "no"
 * @param {Integer} height [n=20]
 * @param {Integer} width [n=20]
 * @param {Integer} checkHeight [n=9]
 * @param {Integer} checkWidth [n=5]
 * @param {String} checkColor [n='#ffffff']
 * @param {String} borderColor [n='#3f6b9e']
 * @param {String} backgroundColor [n='#eff5fc']
 * @param {String} checkedBackgroundColor [n='#eff5fc']
 * @param {String} id [Supports labels using the "for" property. i.e. - you can click a label]
 * @example
 *   import { Checkbox } from 'shared/components/';
 *
 *   <Checkbox
 *     name="dispensaryTypeMedical"
 *     value="medical"
 *     height={18}
 *     width={18}
 *     checkColor="#ffffff"
 *     borderColor="#3f6b9e"
 *     backgroundColor="#eff5fc"
 *     checkedBackgroundColor="#eff5fc"
 *   />
 *
 * @example - Using 'react-final-form'
 *
 *   <Field
 *     name="dispensaryTypeMedical"
 *     value="medical"
 *     type="checkbox"
 *     height={18}
 *     width={18}
 *     render={({ input, meta, ...rest }) => <Checkbox {...input} {...rest} />}
 *   /> Medical
 */

// the extend here helps TS infer onChange props and such being passed in
/* eslint-disable-next-line @typescript-eslint/consistent-type-definitions */
interface Props extends React.HTMLAttributes<any> {
  [x: string]: any;
  id?: string;
  height?: number;
  width?: number;
  checkedBackgroundColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  checkWidth?: number;
  checkHeight?: number;
  checkColor?: string;
}

const Container = styled.div<Props>`
  /* Customize the label (the container) */
  display: block;
  position: relative;
  cursor: pointer;
  height: ${(props) => `${props.height ?? 20}px`};
  width: ${(props) => `${props.width ?? 20}px`};
  user-select: none;
`;

const StyledCheckbox = styled.input<Props>`
  /* Hide the browser's default checkbox */
  position: absolute;
  opacity: 0;
  height: ${(props) => `${props.height ?? 20}px`};
  width: ${(props) => `${props.width ?? 20}px`};
  z-index: 1;
  cursor: pointer;

  /* When the checkbox is checked, add a background color */
  &:checked ~ span {
    background-color: ${(props) => props.checkedBackgroundColor ?? '#3bba5b'};
    border-color: ${(props) => props.checkedBackgroundColor ?? '#3bba5b'};
  }

  /* Show the indicator (dot/circle) when checked */
  &:checked ~ span:after {
    display: block;
  }
`;

const CustomCheckbox = styled.span<Props>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => `${props.height ?? 20}px`};
  width: ${(props) => `${props.width ?? 20}px`};
  background-color: ${(props) => props.backgroundColor ?? '#eff5fc'};
  border: 1px solid ${(props) => props.borderColor ?? '#3f6b9e'};
  border-radius: 2px;

  /* Create the indicator (checkmark) */
  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 45%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: ${(props) => `${props.checkWidth ?? 5}px`};
    height: ${(props) => `${props.checkHeight ?? 9}px`};
    border: solid ${(props) => props.checkColor ?? '#ffffff'};
    box-shadow: 0 1px 0 0 #2b8943;
    border-width: 0 2px 2px 0;
  }
`;

export const Checkbox = ({ id, ...props }: Props): JSX.Element => (
  <Container {...props}>
    <StyledCheckbox {...props} id={id} type='checkbox' />
    <CustomCheckbox {...props} />
  </Container>
);

export default Checkbox;
