import React from 'react';
import {
  layout,
  space,
  fontSize,
  maxWidth,
  LayoutProps,
  SpaceProps,
  FontSizeProps,
  MaxWidthProps,
} from 'styled-system';
import styled, { StyledProps } from 'styled-components';
import _ from 'lodash';
import tc from 'tinycolor2';

import { SmallLoader } from 'src/shared/components/loading';
import useStyledTheme from 'src/shared/hooks/use-styled-theme';
import { withDefaultProps } from 'shared/utils/type-utils';

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type ButtonProps = FontSizeProps &
  LayoutProps &
  MaxWidthProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  SpaceProps & {
    className?: string;
    loading?: boolean;
    height?: string;
    disabled?: boolean;
    inverted?: boolean;
    link?: boolean;
    gray?: boolean;
    children: React.ReactNode;
    backgroundColor?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };

const StyledButton = withDefaultProps(
  // pulling out some props that shouldn't to be passed to the HTML button element
  styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ loading: _loading, inverted: _inverted, link: _link, type = 'submit', ...rest }: StyledProps<ButtonProps>) => (
      // dont know why this gets triggered when the type must be a string of 'submit' | 'reset' | 'button' AND has a default
      // eslint-disable-next-line react/button-has-type
      <button {...rest} type={type} />
    )
  )`
    background-color: ${({ theme }) => theme.colors.ctaPrimary};
    border-radius: 33px;
    border: none;
    box-shadow: none;
    color: ${(props) => (props.loading ? 'transparent !important' : '#FFF')};
    cursor: ${(props) => (props.loading || props.disabled ? 'not-allowed' : 'pointer')};
    display: block;
    font: bold 14px/34px proxima-nova, sans-serif;
    height: 40px;
    padding: 0 18px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.ctaPrimary};
    line-height: 1;
    transition: background-color 86ms ease-out;
    white-space: nowrap;
    ${(props) => {
      if (props.height === '33px') {
        return `line-height: 2.4;`;
      }
      if (props.height === '27px') {
        return `line-height: 2;`;
      }
      return `line-height: 2.5;`;
    }}

    ${layout}
    ${space}
    ${fontSize}
    ${maxWidth}
    &:hover, &:active, &:focus {
      background-color: ${({ theme }) => tc(theme.colors.ctaPrimary).darken(21).desaturate(24).toRgbString()};
      border: none;
      box-shadow: none;
      color: #fff;
      outline: none;
    }

    &:focus:not(:hover) {
      background-color: ${({ theme }) => theme.colors.ctaPrimary};
    }

    &.--is-disabled,
    &:disabled,
    &:disabled:hover {
      background-color: #c6c4c4;
    }

    ${(props) => {
      if (props.disabled) {
        return `
        background-color: #9ea7ab;
        color: #ffffff;
        opacity: 1;
      `;
      }
      if (props.loading) {
        return `
        &:hover, &:active, &:focus {
          background-color: #9ea7ab;
          color: #ffffff;
        }
      `;
      }
      return '';
    }}

    ${(props) =>
      props.inverted &&
      `
      background-color: ${props.backgroundColor ?? 'white'};
      border: 2px solid ${props.theme.colors.ctaPrimary};
      line-height: 2.2;
      color: ${props.loading ? 'transparent' : props.theme.colors.ctaPrimary};
      &:hover, &:active, &:focus {
        background-color: #f2f2f2;
        border: 2px solid ${props.theme.colors.ctaPrimary};
        color: ${props.theme.colors.ctaPrimary};
      }
      &:focus:not(:hover) {
        background-color: #f2f2f2;
      }
    `}

    ${(props) =>
      props.gray &&
      `
      background-color: #a3b2c1;
      color: ${props.loading ? 'transparent' : '#FFF'};
      &:hover, &:active {
        background-color: #777f85;
      }
      &:focus:not(:hover),
      &:disabled:hover {
        background-color: #a3b2c1;
      }
      opacity: ${props.disabled ? '0.5' : '1'};
    `}

    ${(props) =>
      props.link
        ? `
          background-color: #fff;
          color: ${props.loading ? 'transparent' : props.theme.colors.ctaPrimary};
          font-weight: normal;
          text-transform: none;
          &:hover, &:active {
            color: ${props.theme.colors.ctaPrimary};
            background-color: #fff;
          }
          &:focus {
            background-color: #fff;
          }
        `
        : ''}
  `,
  {
    theme: {
      colors: {
        ctaPrimary: '#4597e0',
      },
    },
  }
);

export const Button = ({
  loading = false,
  disabled = false,
  children,
  inverted = false,
  link = false,
  onClick = _.constant(undefined),
  ...props
}: ButtonProps): JSX.Element => {
  const theme = useStyledTheme();
  const spinnerColor = inverted ? theme.colors.ctaPrimary : '#fff';
  return (
    <StyledButton
      loading={loading}
      disabled={disabled}
      inverted={inverted}
      link={link}
      onClick={disabled || loading ? _.constant(undefined) : onClick}
      {...props}
    >
      {children}
      {loading && (
        <LoaderContainer>
          <SmallLoader height={20} color={spinnerColor} />
        </LoaderContainer>
      )}
    </StyledButton>
  );
};

export default Button;
