import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { when } from 'mobx';
import { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import { useStores } from 'src/state';
import { useRouter } from 'next/router';
import ModalContainer from 'src/shared/modals/container';
import LoginForm from 'src/components/forms/login-form';
import { LoginFormInput } from 'src/components/forms/login-form/login-form-schema';

export type LoginModalProps = {
  onSuccess: () => void;
  onOpenForgotPassword: () => void;
};

export function sanitizeRedirectUrlParam(path: string[] | string | undefined): string {
  return typeof path === 'string' ? path.replace(/[^A-Za-z0-9\\/-]/g, '') : '';
}

export function isSuspiciousUrl(redirect: string[] | string | undefined, santiziedRedirect: string): boolean {
  if (redirect === undefined && santiziedRedirect === '') {
    return false;
  }
  return redirect !== santiziedRedirect;
}

function LoginModal({ onOpenForgotPassword, onSuccess }: LoginModalProps): JSX.Element {
  const { ui, user } = useStores();
  const { t } = useTranslation();
  const { redirect } = useRouter().query;

  const validationMessageMap = {
    email: {
      required: t('login.errors.email.required', 'email is a required field'),
      email: t('login.errors.email.email', 'email must be a valid email'),
    },
    password: {
      required: t('login.errors.password.required', 'password is a required field'),
    },
  };

  const handleSubmit: SubmitHandler<LoginFormInput> = async ({ email, password }): Promise<void> => {
    const santiziedRedirect = sanitizeRedirectUrlParam(redirect);

    if (isSuspiciousUrl(redirect, santiziedRedirect)) {
      throw new Error(`Suspicious URL is detected`);
    }

    const response = await user.login(email, password);

    if (response.success) {
      when(
        () => user.exists,
        () => void onSuccess()
      );
      return;
    }

    const defaultMessage = t(
      'errors.user.invalidLogin',
      "You've entered an incorrect email or password. Please try again."
    );
    const message = _.get(response, 'error.graphQLErrors[0].extensions.errors[0].detail', defaultMessage);

    ui.showErnie(message, 'danger');
  };

  const handleError: SubmitErrorHandler<LoginFormInput> = (errors) => {
    const knownError = Object.entries(errors).some((entry) => {
      const [key, value] = entry;
      const formField = validationMessageMap[key as keyof typeof validationMessageMap];
      const message = formField[value.type as keyof typeof formField] as string | undefined;
      if (message) {
        ui.showErnie(message, 'danger');
        return true;
      }
      return false;
    });
    if (!knownError) {
      console.error(errors);
      ui.showErnie(t('login.submit.error', 'Error logging in'));
    }
  };

  return (
    <ModalContainer
      data-cy='login-modal'
      data-test='login-modal'
      isOpen
      width='495px'
      height='335px'
      p='31px 47px 35px 47px'
    >
      <LoginForm onSubmit={handleSubmit} onError={handleError} onOpenForgotPassword={onOpenForgotPassword} />
    </ModalContainer>
  );
}

export default observer(LoginModal);
