import React, { forwardRef } from 'react';
import { Property as CSSProperty } from 'csstype';
import {
  space,
  height,
  color,
  top,
  width as styledWidth,
  right as styledRight,
  display as styledDisplay,
  fontSize as styledFontSize,
  borderRadius,
  ColorProps,
  HeightProps,
  TopProps,
  RightProps,
  WidthProps,
  DisplayProps,
  FontSizeProps,
  SpaceProps,
  BorderRadiusProps,
  MarginTopProps,
  PaddingRightProps,
  ResponsiveValue,
  Theme,
  BackgroundColorProps,
  MinWidthProps,
  minWidth,
} from 'styled-system';
import styled from 'styled-components';

type SelectContainerProps = ColorProps & DisplayProps & MinWidthProps & RightProps & SpaceProps & TopProps & WidthProps;
export const SelectContainer = styled.span<SelectContainerProps>`
  ${styledWidth}
  ${minWidth}
  ${color}
  ${styledDisplay}
  ${space}
  position: relative;
  cursor: pointer;

  &:after {
    border: 1px solid #46494c;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    border-top: 5px solid #797e83 !important;
    border-bottom: none !important;
    transform: none !important;
    content: ' ';
    display: block;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    z-index: 1;
    top: 47%;
    ${styledRight}
    ${top}
  }

  &.--overflow {
    &:before {
      content: '...';
      position: absolute;
      top: 29%;
      right: 26px;
      width: 22px;
      z-index: 2;
      background-color: white;
    }
  }
`;

type BaseStyledSelectProps = React.HTMLAttributes<HTMLSelectElement> & {
  border?: CSSProperty.Border;
  disabled?: boolean;
  error?: boolean;
};
type StyledSelectProps = BackgroundColorProps &
  BaseStyledSelectProps &
  BorderRadiusProps &
  FontSizeProps &
  HeightProps &
  SpaceProps;

export const StyledSelect = styled.select<StyledSelectProps>`
  width: 100%;
  height: 100%;
  min-width: 50px;
  padding: 2px 31px 0px 12px;
  align-items: center;
  border: ${(props) => props.border ?? '1px solid transparent'};
  border-radius: 3px;
  box-shadow: none;
  position: relative;
  border-color: ${(props) => (props.error ? '#e25241 !important' : '#d1d5da')};
  color: #6d747b;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: block;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 45px;
  font-size: 13px;
  background: inherit;
  ${space}
  ${height}
  ${styledFontSize}
  ${borderRadius}

  &:active,
  &:focus {
    border: ${(props) => props.border ?? '1px solid #dbdbdb'};
    box-shadow: none;
  }

  &:hover {
    border: ${(props) => props.border ?? '1px solid #b6b6b6'};
  }

  /* remove outline on FF */
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #6d747b;
  }
  option:not(:checked) {
    color: #707478;
  }

  @media ${({ theme }) => theme.mediaQueries.largePhone} {
    font-size: 14px;
  }
`;

type BaseSelectProps = React.HTMLAttributes<HTMLSelectElement> & {
  carrotTop?: ResponsiveValue<CSSProperty.Top, Required<Theme>>;
  value: string;
};
type SelectProps = BackgroundColorProps &
  BaseSelectProps &
  DisplayProps &
  FontSizeProps &
  HeightProps &
  MarginTopProps &
  MinWidthProps &
  PaddingRightProps &
  RightProps &
  SpaceProps &
  WidthProps;

export const Select = forwardRef<HTMLSelectElement, SelectProps>((
  /** @type { any } */
  {
    width,
    bg = '#fcfdfe',
    backgroundColor = 'transparent',
    right = '18px',
    display = ['block'],
    fontSize = ['16px', '16px', '16px', '13px'],
    carrotTop,
    className,
    mt,
    pr,
    ...props
  },
  ref
) => (
  <SelectContainer
    bg={bg}
    className={className}
    display={display}
    mt={mt}
    right={right}
    top={carrotTop}
    width={width}
    pr={pr}
  >
    <StyledSelect ref={ref} fontSize={fontSize} bg={backgroundColor} backgroundColor={backgroundColor} {...props} />
  </SelectContainer>
));

export default Select;
