import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useForm, FormProvider, SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { useYupValidationResolver } from 'src/hooks/use-yup-resolver';

import { mediaQueries } from 'src/shared/styles';
import { ResetButton } from 'src/shared/components/forms/inputs/reset-button';
import ModalButton from 'src/shared/modals/button';
import ModalSecondaryHeader from 'src/shared/modals/header';
import { loginFormSchema, LoginFormInput } from './login-form-schema';
import { EmailField, PasswordField } from './form-fields';

type LoginFormProps = {
  onError: SubmitErrorHandler<LoginFormInput>;
  onOpenForgotPassword: () => void;
  onSubmit: SubmitHandler<LoginFormInput>;
};

const LoginForm = ({ onSubmit, onError, onOpenForgotPassword }: LoginFormProps): JSX.Element => {
  const { t } = useTranslation();
  const reactHookFormMethods = useForm<LoginFormInput>({
    mode: 'onSubmit',
    resolver: useYupValidationResolver<LoginFormInput>(loginFormSchema),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = reactHookFormMethods;

  return (
    <FormProvider {...reactHookFormMethods}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <ModalSecondaryHeader lowercase>{t('login.log_in', 'Log In')}</ModalSecondaryHeader>
        <EmailField />
        <PasswordField />

        <ResetPasswordContainer>
          <ResetPassword
            data-cy='forgot-password-link'
            data-test='forgot-password-link'
            onClick={onOpenForgotPassword}
            type='button'
          >
            {t('login.forgot_password', 'Forgot your password?')}
          </ResetPassword>
        </ResetPasswordContainer>

        <ModalButton
          aria-label='login button'
          data-cy='login-button-submit'
          data-test='login-button-submit'
          width='100%'
          mt='18px'
          loading={isSubmitting}
          type='submit'
        >
          {t('login.log_in', 'Log In')}
        </ModalButton>
      </Form>
    </FormProvider>
  );
};

const ResetPasswordContainer = styled.div`
  text-align: left;
  width: 100%;
`;

const ResetPassword = styled(ResetButton)`
  color: #8f989e;
  font-size: 13px;
  justify-content: flex-start;
  line-height: 15px;
  padding-left: 105px;
  text-decoration: underline;

  @media ${mediaQueries.largePhone} {
    padding-left: 0;
  }
`;

const Form = styled.form`
  width: 100%;
`;

export default observer(LoginForm);
