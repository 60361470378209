import { ApolloLink } from 'apollo-link';
export function VersionLink(onUpdateVersion) {
  return new ApolloLink(function (operation, forward) {
    return (// eslint-disable-next-line lodash/prefer-lodash-method
      forward(operation).map(function (data) {
        var dutchieVersion = operation.getContext().response.headers.get('x-dutchie-version');

        if (dutchieVersion) {
          onUpdateVersion(dutchieVersion);
        }

        return data;
      })
    );
  });
}