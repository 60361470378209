// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export {
  CategoryOptions,
  DefaultImages,
  DefaultImagesList,
  getAllOptions,
  IMGIX_SOURCE,
  isDefaultImage,
  LEGACY_AWS_SOURCE,
  MG_TO_G,
  MILLIGRAMS,
  MILLILITERS,
  NUMERALS_DOLLAR_FORMAT,
  POTENCY_TYPES,
  POTENCY_UNITS_DISPLAY,
  POTENCY_UNITS,
  productEffects,
  SCORE_DESCRIPTION_LENGTH_MINIMUM,
  StrainTypeOptions,
  StrainTypeOrder,
  WeightedSubcategories,
  logoPlaceholder,
} from 'shared/constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export { statesMap } from 'shared/core/constants/geography';
export { getComplianceConfig } from 'shared/core/helpers/compliance';

// default page size of an API list response
export const PER_PAGE = 50;
