import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Button } from 'src/shared/components/forms/inputs';
import { Tooltip } from 'src/shared/components';

type ButtonContainerProps = {
  flexShrink?: string;
  width: Array<number | string> | string;
  mt: Array<number | string> | string;
  mb: Array<number | string> | string;
  flexDirection: string;
  alignSelf: string;
  alignItems: string;
  justifyContent: string;
};

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  flex-shrink: ${(props: ButtonContainerProps) => props.flexShrink};
  margin-top: ${(props: ButtonContainerProps) => props.mt};
  margin-bottom: ${(props: ButtonContainerProps) => props.mb};
  flex-direction: ${(props: ButtonContainerProps) => props.flexDirection};
  align-self: ${(props: ButtonContainerProps) => props.alignSelf};
  align-items: ${(props: ButtonContainerProps) => props.alignItems};
  justify-content: ${(props: ButtonContainerProps) => props.justifyContent};
  width: ${(props: ButtonContainerProps) => props.width};
`;

type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'width'> & {
  children: React.ReactNode;
  width?: (number | string)[] | (number | string);
  mt?: number[] | string;
  height?: string;
  mb?: number[];
  loading?: boolean;
  alignSelf?: string;
  alignItems?: string;
  buttonContainerWidth?: string;
  inverted?: boolean;
  link?: boolean;
  tooltip?: React.ReactElement;
  flexShrink?: string;
};

export const ModalButton = observer((
  /** @type any */ {
    children,
    width = [1, 1, 1, 'auto'],
    mt = [20],
    mb = [0],
    loading = false,
    alignSelf = 'auto',
    alignItems = 'center',
    buttonContainerWidth = '100%',
    inverted = false,
    link = false,
    tooltip,
    flexShrink = '0',
    ...props
  }: ButtonProps
) => (
  <ButtonContainer
    width={buttonContainerWidth}
    mt={mt}
    mb={mb}
    flexDirection='column'
    alignItems={alignItems}
    justifyContent='center'
    alignSelf={alignSelf}
    flexShrink={flexShrink}
    className='modal-button-container'
  >
    {!tooltip && (
      <Button width={width} loading={loading} inverted={inverted} link={link} {...props}>
        {children}
      </Button>
    )}
    {tooltip && (
      <Tooltip
        mainCopy={
          <Button width={width} loading={loading} inverted={inverted} link={link} {...props}>
            {children}
          </Button>
        }
        tooltipCopy={tooltip}
        hoverMain
        right='-29px'
        bottom='44px'
      />
    )}
  </ButtonContainer>
));

export default ModalButton;
