import React from 'react';
import styled from 'styled-components';

import { ModalContainer, ModalButton, ModalSecondaryHeader, ModalCopy } from 'src/shared/modals';

type PriceWarningModalProps = {
  isOpen: boolean;
  onClickNo: VoidFunction;
  onClickYes: VoidFunction;
};

export function PriceWarningModal({ isOpen, onClickNo, onClickYes }: PriceWarningModalProps): JSX.Element {
  return (
    <ModalContainer width='462px' padding='30px 57px 52px' isOpen={isOpen}>
      <ModalSecondaryHeader fontSize='18px'>HOLD UP!</ModalSecondaryHeader>

      <ModalCopy>
        The recreational and medical prices you entered appear to have an unusually large difference. Are you sure these
        prices are correct?
      </ModalCopy>

      <Flex>
        <ModalButton buttonContainerWidth='119px' width='102px' onClick={onClickYes}>
          Yes
        </ModalButton>

        <ModalButton inverted buttonContainerWidth='119px' width='102px' onClick={onClickNo}>
          No
        </ModalButton>
      </Flex>
    </ModalContainer>
  );
}

export default PriceWarningModal;

const Flex = styled.div`
  display: flex;
  margin-top: 10px;
`;
