import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

type FieldLabelStylesProps = React.HTMLAttributes<HTMLLabelElement> & {
  color: string;
};
const FieldLabelStyles = styled.label<FieldLabelStylesProps>`
  color: ${(props) => props.color};
  font-weight: bold;
`;

type FieldLabelContainerProps = LayoutProps &
  SpaceProps & {
    top?: boolean;
  };

const FieldLabelContainer = styled.div<FieldLabelContainerProps>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 50px;
  justify-content: ${(props) => (props.top ? 'flex-start' : 'center')};
  margin-right: 1.5rem;
  text-align: right;
  width: ${(props) => props.width};
  ${layout}
  ${space}

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    height: auto;
    padding-bottom: 5px;
  }
`;

type FieldLabelProps = FieldLabelContainerProps & {
  htmlFor?: string;
  color?: string;
  children?: React.ReactNode;
};

export function FieldLabel(props: FieldLabelProps): JSX.Element {
  const { children, width = '115px', top = false, color = '#707478', htmlFor, ...other } = props;
  return (
    <FieldLabelContainer width={width} top={top} {...other}>
      <FieldLabelStyles htmlFor={htmlFor} color={color}>
        {children}
      </FieldLabelStyles>
    </FieldLabelContainer>
  );
}
