import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

import { SidebarHeader } from 'src/components/layout/sidebar-header';
import { SidebarLink } from 'src/components/layout/sidebar-link';
import { SidebarFooter } from 'src/components/layout/sidebar-footer';

import { useSidebarLinks } from 'src/hooks/use-sidebar-links';

type Props = {
  isOpen: boolean;
  closeDrawer: () => void;
};

export default function Sidebar({ isOpen, closeDrawer }: Props): JSX.Element {
  const links = useSidebarLinks();
  const { t } = useTranslation();

  return (
    <Container isOpen={isOpen}>
      <CloseButton onClick={closeDrawer} />
      <SidebarHeader title={t('app.title', 'Brand Portal')} />
      <LinksContainer>
        {links.map((link) => link.visible && <SidebarLink link={link} key={link.href} onClick={closeDrawer} />)}
      </LinksContainer>
      <SidebarFooter />
    </Container>
  );
}

const LinksContainer = styled.div`
  flex: 1 0 0%;
  justify-content: flex-start;
  min-height: 0;
`;

const Container = styled.div<Pick<Props, 'isOpen'>>`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  font-size: 14px;
  height: auto;
  justify-content: space-between;
  left: 0;
  min-height: 100vh;
  min-width: 0;
  position: fixed;
  top: 0;
  width: 200px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    box-shadow: 5px 0 7px 0 ${({ theme }) => theme.colors.basicShadow};
    border-right: 1px solid ${({ theme }) => theme.colors.v2Border1};
    width: 375px;
    z-index: 8;
    left: ${(p) => (p.isOpen ? '0' : '-376px')};
    transition: ${(p) => (p.isOpen ? 'left 0.4s' : '')};
  }

  @media ${({ theme }) => theme.mediaQueries.phone} {
    width: 100%;
    left: ${(p) => (p.isOpen ? '0' : 'calc(-100% - 1px)')};
  }
`;

const CloseButton = styled.img.attrs(() => ({
  src: '/icons/menu-icon-close-white.svg',
}))`
  position: absolute;
  top: 10px;
  right: 22px;
  width: 20px;
  height: 35px;
  object-fit: contain;
  display: none;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    display: block;
  }
`;
