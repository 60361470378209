import { useContext } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';

/*
Note: for when this is moved back to shared

would suggest overriding the styled-components DefaultTheme type
this enables theme props to be correclty typed in styled declarations

see brands/types/styled.d.ts for example
*/
export default function useStyledTheme(): DefaultTheme {
  const theme = useContext(ThemeContext);
  return theme;
}
