// NOTE: This is a shim for next.js router found in this issue:
// https:github.com/pbeshai/use-query-params/issues/13#issuecomment-607785336

// ANOTHER NOTE: this is a TS converted file that exists in Marketplace
// marketplace/src/utils/query-param-provider.jsx
import React, { memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { PushReplaceHistory, QueryParamProvider as ContextProvider } from 'use-query-params';

type QueryParamProviderProps = {
  [x: string]: any;
  children: React.ReactNode;
};
const QueryParamProvider = (props: QueryParamProviderProps): JSX.Element => {
  const { children, ...rest } = props;
  const router = useRouter();
  const match = /[^?]+/.exec(router.asPath);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo<Location>(
    () =>
      ({
        search: router.asPath.replace(/[^?]+/u, ''),
      } as Location),
    [router.asPath]
  );

  const history = useMemo<PushReplaceHistory>(
    () => ({
      push: ({ search }: Location): void => {
        void router.push(`${router.pathname}${search}`, { search, pathname }, { shallow: true });
      },
      replace: ({ search }: Location): void => {
        void router.replace(`${router.pathname}${search}`, { search, pathname }, { shallow: true });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, router.pathname, location]
  );

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  );
};

const MemoizedQueryParamProvider = memo(QueryParamProvider);

export default MemoizedQueryParamProvider;
