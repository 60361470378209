import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { FieldGroup, FieldLabel, FieldBody } from 'src/shared/components/forms';
import { ImageModal, ImageModalProps } from 'src/shared/modals';
import styled from 'styled-components';

type EditProductImageProps = {
  imageToUse?: string;
  handleFile: React.ChangeEventHandler<HTMLInputElement>;
  onClickSave: (imageUrl: string) => void;
  onError: ImageModalProps['onError'];
};

export function EditProductImage(props: EditProductImageProps): JSX.Element {
  const { t } = useTranslation();
  const { imageToUse = '', handleFile, onClickSave, onError } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = (): void => setModalOpen(false);
  const openModal = (): void => setModalOpen(true);

  function handleClickSave(imageUrl: string): void {
    closeModal();
    onClickSave(imageUrl);
  }

  return (
    <FieldGroup>
      <StyledFieldLabel top>{t('product-form.labels.image', 'Image')}:</StyledFieldLabel>

      <FieldBody justifyContent='flex-start'>
        {imageToUse && <ImageThumbnail src={imageToUse} />}
        <Flex mt={imageToUse && '10px'}>
          {imageToUse && (
            <>
              <EditButton onClick={openModal}>{t('product-form.actions.editImage', 'Edit')}</EditButton>
              &nbsp; | &nbsp;
            </>
          )}
          <EditButton htmlFor='product-image-input'>{t('product-form.actions.uploadImage', 'Upload new')}</EditButton>
          <HiddenInput id='product-image-input' type='file' onChange={handleFile} />
        </Flex>
      </FieldBody>

      <ImageModal isOpen={modalOpen} onError={onError} onClose={closeModal} onSave={handleClickSave} src={imageToUse} />
    </FieldGroup>
  );
}

export default EditProductImage;

const Flex = styled.div<{ mt: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.mt};
`;

const ImageThumbnail = styled.img`
  width: 115px;
  border: 1px solid #d3dadf;
  border-radius: 3px;
`;

const EditButton = styled.label`
  color: #589cd9;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const StyledFieldLabel = styled(FieldLabel)`
  height: auto;
`;
