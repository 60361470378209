import { observable, action, transaction, computed, runInAction } from 'mobx';
import { isSuperAdmin } from 'src/shared/helpers/users';
import { GetUser, ValidatePasswordResetTokenQuery } from 'src/graphql/users/queries';
import {
  LoginUser,
  ResetPassword,
  SendBrandUserPasswordResetEmail,
  AcceptBrandPortalTos,
} from 'src/graphql/users/mutations';
import { WhenNotVoid } from 'shared/utils/type-utils';
import {
  GqlAcceptBrandPortalTosMutation,
  GqlAcceptBrandPortalTosMutationVariables,
  GqlLoginAdminMutation,
  GqlLoginAdminMutationVariables,
  GqlMeAdminQuery,
  GqlMeAdminQueryVariables,
  GqlResetPasswordV2Mutation,
  GqlResetPasswordV2MutationVariables,
  GqlSendBrandUserPasswordResetEmailMutation,
  GqlSendBrandUserPasswordResetEmailMutationVariables,
  GqlValidatePasswordResetTokenQueryQuery,
  GqlValidatePasswordResetTokenQueryQueryVariables,
  Maybe,
} from 'types/graphql';
import { ROLES } from 'src/utils/constants';
import { RootState } from './root';

type User = GqlMeAdminQuery['meAdmin'];
type UserProfile = WhenNotVoid<User>['profile'];

type LoginResponse =
  | {
      error: any;
      success: boolean;
    }
  | {
      success: boolean;
      error?: undefined;
    };

type SendBrandUserPasswordResetEmailResponse = {
  success: boolean;
  data?: GqlSendBrandUserPasswordResetEmailMutation | null;
  err?: any;
};

type ResetPasswordInput = {
  password: string;
  token: string;
};

type ResetPasswordResponse = {
  success: boolean;
  data?: GqlResetPasswordV2Mutation | null;
  err?: any;
};

export class UserState {
  private readonly rootState: RootState;

  @observable
  _user?: User;

  @observable
  profile?: UserProfile;

  @observable
  email?: string;

  @observable
  id?: string;

  constructor(private readonly rootStore: RootState) {
    this.rootState = rootStore;
  }

  @computed
  get exists(): boolean {
    if (this.user && this.token) {
      return true;
    }

    return false;
  }

  @computed
  get role(): ROLES {
    if (this.user?.profile.type === 'superAdmin') {
      return ROLES.SUPER_ADMIN;
    }
    if (this.user?.profile.type === 'brandUser') {
      if (this.user.profile.permissions?.canManageBrandGroup) {
        return ROLES.BRAND_ADMIN;
      }
      return ROLES.BRAND_USER;
    }
    return ROLES.UNAUTHORIZED;
  }

  @computed
  get isSuperAdmin(): boolean {
    return isSuperAdmin(this.user);
  }

  @computed
  get isBrandAdmin(): boolean {
    return this.user?.profile.type === 'brandUser' && !!this.user.profile.permissions?.canManageBrandGroup;
  }

  @computed
  get isBrandUser(): boolean {
    return this.user?.profile.type === 'brandUser' && !this.user.profile.permissions?.canManageBrandGroup;
  }

  get token(): string {
    // We may not always be rendering in a browser with next.js
    if (typeof window === 'undefined') {
      return '';
    }

    return window.localStorage.getItem('access-token') ?? '';
  }

  @action
  set token(accessToken: Maybe<string> | undefined) {
    if (accessToken) {
      window.localStorage.setItem('access-token', accessToken);
    } else {
      window.localStorage.removeItem('access-token');
    }
  }

  @computed
  get user(): User | undefined {
    return this._user;
  }

  set user(user: User | undefined) {
    runInAction(() => {
      if (user) {
        transaction(() => {
          this._user = user;
          this.email = user.emails[0]?.address;
          this.id = user._id;
          this.profile = user.profile;
        });
      } else {
        transaction(() => {
          this._user = undefined;
          this.email = '';
          this.id = '';
          this.profile = {};
        });
      }
    });
  }

  @action.bound
  async sendBrandUserPasswordResetEmail(email: string): Promise<SendBrandUserPasswordResetEmailResponse> {
    try {
      const response = await this.rootStore.apolloClient.mutate<
        GqlSendBrandUserPasswordResetEmailMutation,
        GqlSendBrandUserPasswordResetEmailMutationVariables
      >({
        mutation: SendBrandUserPasswordResetEmail,
        variables: { email },
      });
      return { success: true, data: response.data };
    } catch (err) {
      return { success: false, err };
    }
  }

  @action.bound
  async validatePasswordResetToken(token: string): Promise<boolean> {
    const response = await this.rootStore.apolloClient.query<
      GqlValidatePasswordResetTokenQueryQuery,
      GqlValidatePasswordResetTokenQueryQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: ValidatePasswordResetTokenQuery,
      variables: {
        token,
      },
    });

    return response.data.validatePasswordResetTokenQuery?.isValid ?? false;
  }

  @action.bound
  async resetPassword(input: ResetPasswordInput): Promise<ResetPasswordResponse> {
    try {
      const response = await this.rootStore.apolloClient.mutate<
        GqlResetPasswordV2Mutation,
        GqlResetPasswordV2MutationVariables
      >({
        mutation: ResetPassword,
        variables: { input },
      });
      return { success: true, data: response.data };
    } catch (err) {
      return { success: false, err };
    }
  }

  @action.bound
  async acceptBrandPortalTos(token: string): Promise<{ success: boolean; err?: any }> {
    try {
      const response = await this.rootStore.apolloClient.mutate<
        GqlAcceptBrandPortalTosMutation,
        GqlAcceptBrandPortalTosMutationVariables
      >({
        mutation: AcceptBrandPortalTos,
        variables: { token },
      });
      return { success: !!response.data?.acceptBrandPortalTos?.success };
    } catch (err) {
      return { success: false, err };
    }
  }

  @action.bound
  async login(email: string, password: string): Promise<LoginResponse> {
    try {
      const response = await this.rootState.apolloClient.mutate<GqlLoginAdminMutation, GqlLoginAdminMutationVariables>({
        mutation: LoginUser,
        variables: {
          email,
          password,
        },
      });

      this.token = response.data?.loginAdmin?.accessToken;
      await this.fetch();

      if (this.user?.profile.active === false) {
        this.logout();
        return { success: false };
      }

      return { success: true };
    } catch (error) {
      console.error(error);
      return { error, success: false };
    }
  }

  @action.bound
  logout(): void {
    void window.localStorage.removeItem('access-token');
  }

  @action.bound
  async fetch(): Promise<boolean> {
    if (!this.token) {
      console.warn('No token configured before calling user.fetch(), This is a no-op.');
      return false;
    }

    try {
      const response = await this.rootState.apolloClient.query<GqlMeAdminQuery, GqlMeAdminQueryVariables>({
        fetchPolicy: 'no-cache',
        query: GetUser,
      });

      this.user = response.data.meAdmin;

      return true;
    } catch (e: any) {
      if (e.networkError) {
        // If network error, don't reset whole session.
        console.warn('network error');
        return false;
      }

      console.warn('Error Fetching User', e);
      return false;
    }
  }
}

export default UserState;
