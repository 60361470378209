import React from 'react';
import { Provider as MobxProvider } from 'mobx-react';
import ApolloClient from 'src/utils/apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { LDMultiKindContext } from 'launchdarkly-js-client-sdk';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PublicEnv from 'shared/utils/public-env';
import { defaultUser } from 'shared/constants/users';
import RootState from 'src/state/root';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import Ernie from 'src/shared/components/ernie';
import ConfirmModal from 'src/components/modals/confirm-modal';
import { ThemeProvider as StyledComponentsProvider } from 'styled-components';
import { ThemeProvider as MuiProvider, StylesProvider } from '@material-ui/core/styles';
import { styledComponentsTheme, muiTheme } from 'src/shared/themes';
import { GlobalStyles } from 'src/shared/styles';
import QueryParamProvider from 'src/shared/utils/query-param-provider';

import LayoutRenderer from './_layout';
import ProtectedRoute from './_protected_route';

// eslint-disable-next-line @typescript-eslint/naming-convention
function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head>
        <title>Dutchie | Brand Portal</title>
      </Head>
      <MobxProvider {...RootState.stores}>
        <QueryParamProvider>
          <ApolloProvider client={ApolloClient}>
            <StyledComponentsProvider theme={styledComponentsTheme}>
              <MuiProvider theme={muiTheme}>
                <StylesProvider injectFirst>
                  <GlobalStyles />
                  <ProtectedRoute>
                    <Ernie />
                    <LayoutRenderer>
                      <ConfirmModal />
                      <Component {...pageProps} />
                    </LayoutRenderer>
                  </ProtectedRoute>
                </StylesProvider>
              </MuiProvider>
            </StyledComponentsProvider>
          </ApolloProvider>
        </QueryParamProvider>
      </MobxProvider>
    </>
  );
}

const context: LDMultiKindContext = {
  kind: 'multi',
  user: { ...defaultUser },
};

const RootWithLD = withLDProvider({
  clientSideID: PublicEnv.launchDarklyClientId,
  reactOptions: { useCamelCaseFlagKeys: false },
  context,
})(appWithTranslation(MyApp) as any);

export default RootWithLD;
