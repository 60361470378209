/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars, no-unused-vars */
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { GraphQLError } from 'graphql';

// @ts-ignore
import ApolloUtils from 'shared/utils/apollo-client-utils';
// @ts-ignore
import PublicEnv from 'shared/utils/public-env';

let clientInstance: ApolloClient<NormalizedCacheObject> | undefined;

const handleGraphQLError = (e: GraphQLError): void => console.error(e.message);

function configureApolloClient(): ApolloClient<NormalizedCacheObject> {
  return ApolloUtils.setupMainApolloClient('brands', {
    onAuthError: handleGraphQLError,
    onSessionExpired: handleGraphQLError,
  });
}

function getApolloClientInstance(): ApolloClient<NormalizedCacheObject> {
  if (clientInstance) {
    return clientInstance;
  }

  clientInstance = configureApolloClient();

  return clientInstance;
}

export default getApolloClientInstance();
