import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

export function FullPageLoader({ color = '#61a58b' }): JSX.Element {
  return (
    <Flex>
      <ClipLoader color={color} size={100} />
    </Flex>
  );
}

type SmallLoaderProps = {
  spinnerSize?: number | null;
  height?: number;
  width?: number | null;
  color?: string;
};
export function SmallLoader({
  spinnerSize = null,
  height = 60,
  width = null,
  color = '#61a58b',
  ...props
}: SmallLoaderProps): JSX.Element {
  return (
    <Container height={height} width={width} {...props}>
      <ClipLoader color={color} size={spinnerSize ?? height} />
    </Container>
  );
}

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  min-height: 700px;
`;

type ContainerProps = SmallLoaderProps & { height: number };
const Container = styled.div`
  display: flex;
  width: ${({ width }: ContainerProps) => (width ? `${width}px` : '100%')};
  height: ${({ height }: ContainerProps) => `${height - 10}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
