import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type Props = FlexboxProps & LayoutProps & SpaceProps;

const FieldGroup = styled.div<Props>`
  color: #6d747b;
  display: flex;
  font-size: 13px;
  margin-bottom: 21px;
  position: relative;
  ${space}
  ${layout}
  ${flexbox}

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;

export { FieldGroup };
