import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

type Props = FlexboxProps & LayoutProps & PositionProps & SpaceProps & WidthProps;

const FieldBody = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 13px;
  ${width}
  ${space}
  ${flexbox}
  ${layout}
  ${position}

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    width: 100%;
  }
`;

export { FieldBody };
