import React from 'react';
import styled from 'styled-components';

// TODO: Use shared directory's `reset-button` when it can support ts
// eslint-disable-next-line
export const ResetButton = styled(({ color = '#80919a', ...props }) => <button {...props} />)`
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: ${(props) => (props.color ? props.color : '#363636')};
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export default ResetButton;
