import styled from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';

type ModalSecondaryHeaderProps = FontSizeProps & {
  mb?: string;
  lowercase?: boolean;
};

export const ModalSecondaryHeader = styled.h2<ModalSecondaryHeaderProps>`
  color: #46494c;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: ${(props) => (props.mb ? props.mb : '21px')};
  text-transform: ${(props) => (props.lowercase ? 'none' : 'uppercase')};
  ${fontSize};
`;

export const ModalHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d2d5da;
  background-color: #edf0f3;
  padding: 0 26px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 1;

  & h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #6e747a;
  }
`;

export default ModalSecondaryHeader;
