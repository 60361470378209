import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { StyledInput } from 'src/shared/components/forms/inputs';
import { ElementContainer } from 'src/shared/modals/util';
import { LoginFormInput } from '../login-form-schema';
import { StyledLabel } from './util';

export function EmailField(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register } = useFormContext<LoginFormInput>();
  const { t } = useTranslation();

  return (
    <ElementContainer>
      <StyledLabel>{t('login.email_title', 'Email:')}</StyledLabel>
      <StyledInput
        aria-label='email address input'
        data-cy='email-input'
        data-test='email-input'
        placeholder=''
        type='email'
        name='email'
        ref={register}
      />
    </ElementContainer>
  );
}
