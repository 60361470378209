import _defineProperty from "/ecomm-brands/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _CA;

import { canadianProvincesMap } from 'shared/core/constants/geography'; // country -> state

export var ageRestrictions = {
  "default": 21,
  US: {
    "default": 21
  },
  CA: (_CA = {
    "default": 21
  }, _defineProperty(_CA, canadianProvincesMap.AB, 18), _defineProperty(_CA, canadianProvincesMap.BC, 19), _defineProperty(_CA, canadianProvincesMap.MB, 19), _defineProperty(_CA, canadianProvincesMap.NB, 19), _defineProperty(_CA, canadianProvincesMap.NL, 19), _defineProperty(_CA, canadianProvincesMap.NT, 19), _defineProperty(_CA, canadianProvincesMap.NS, 19), _defineProperty(_CA, canadianProvincesMap.NU, 19), _defineProperty(_CA, canadianProvincesMap.ON, 19), _defineProperty(_CA, canadianProvincesMap.PE, 19), _defineProperty(_CA, canadianProvincesMap.QC, 21), _defineProperty(_CA, canadianProvincesMap.SK, 19), _defineProperty(_CA, canadianProvincesMap.YT, 19), _CA)
};