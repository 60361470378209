/* eslint-disable @typescript-eslint/naming-convention */
export var ERNIE_TIMEOUTS = Object.freeze({
  LONG: 30000,
  MED: 10000,
  SHORT: 5000
});
export var ERNIE_TYPES = Object.freeze({
  DANGER: "danger",
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success"
});
export var ERNIE_DEFAULT_MESSAGES = Object.freeze({
  ERROR: "Something went wrong, please try again.",
  LOGIN: "You've entered an incorrect email or password. Please try again.",
  DUTCHIE_PAY: "Dutchie Pay is unavailable. Please use a different method of payment."
}); // time between messages

export var ERNIE_FADE_TIME = 250;