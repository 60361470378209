import React from 'react';
import styled from 'styled-components';

import { colors } from 'src/shared/themes';
import { DutchieLogo } from 'src/assets/dutchie-logo';

import { useStores } from 'src/state';
import useUserBrand from 'src/hooks/use-user-brand';

type SidebarHeaderProps = {
  title: string;
};

export function SidebarHeader(props: SidebarHeaderProps): JSX.Element {
  const { user } = useStores();
  const { brandImageUrl, brandName } = useUserBrand();
  const { title } = props;

  const headerTitle = brandName ?? title;

  return (
    <Container>
      {brandImageUrl ? <Image src={brandImageUrl} /> : <Logo color={colors.logo} />}
      <TitleText>{headerTitle}</TitleText>
      <EmailText>{user.email}</EmailText>
      <Divider />
    </Container>
  );
}

const Container = styled.div`
  flex: 0 0 auto;
  margin-top: 37px;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 111px;
  max-width: 111px;
  border-radius: 5px;
  outline: 4px solid ${({ theme }) => theme.colors.basicShadow};
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    width: 90%;
  }
`;

const Logo = styled(DutchieLogo)`
  display: block;
  margin: 10px auto 20px;
  width: 111px;
  max-width: 111px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    width: 90%;
  }
`;

const TitleText = styled.div`
  display: block;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 10px;
  padding: 0 5px;
  text-align: center;
`;

const EmailText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.9;
  font-size: 12px;
  margin: 10px 0 24px 0;
  overflow: hidden;
  padding: 0 5px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.2;
  border: none;
  display: block;
  height: 1px;
  margin: 30px auto 24px;
  width: 85%;
`;
