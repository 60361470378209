import React from 'react';
import styled from 'styled-components';
import {
  width as styledWidth,
  height as styledHeight,
  margin as styledMargin,
  display as styledDisplay,
} from 'styled-system';

type TooltipIconProps = {
  display?: string;
  height?: string;
  width?: string;
  src?: string;
  margin?: string;
  color: string;
};
export const TooltipIcon = ({
  display = 'block',
  height = '15px',
  width = '15px',
  src = '/icons/question-mark.svg',
  margin,
  color,
}: TooltipIconProps): JSX.Element => (
  <Icon
    height={height}
    width={width}
    margin={margin}
    src={color === 'grey' ? '/icons/question-mark-grey.svg' : src}
    display={display}
  />
);

const Icon = styled(({ ...props }) => <img {...props} />)`
  margin-left: 7px;
  ${styledHeight}
  ${styledWidth}
  ${styledMargin}
  ${styledDisplay}
`;

export default TooltipIcon;
