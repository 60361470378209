import _defineProperty from "/ecomm-brands/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// Helpful documentation: https://dutchie.atlassian.net/wiki/spaces/EN/pages/1454375031/Overview+of+Compliance+Limit+Checking
export var canadaDefaults = {
  medMinAge: 18,
  recMinAge: 19,
  limits: {
    Flower: {
      medical: Infinity,
      rec: 30.0
    },
    Concentrate: {
      medical: Infinity,
      rec: 7.5
    },
    TinctureTHC: {
      medical: Infinity,
      rec: 7.5
    },
    SolidEdibleNetWeight: {
      medical: Infinity,
      rec: 450
    },
    LiquidEdibleVolumeInOunces: {
      medical: Infinity,
      rec: 578.2198
    },
    solidNetWeightMultiplier: 30 / 450,
    liquidNetWeightMultiplier: 30 / 2100,
    // Although liquid limits are in ounces this is set in terms of grams
    concentrateMultiplier: 4,
    tinctureMultiplier: 4,
    usesEquivalencyCalculator: true,
    usesLiquidTypes: true,
    usesSolidEdibleTypes: true,
    typeMap: {
      Concentrate: {
        categories: ['Concentrate', 'Vaporizers']
      }
    },
    ignoreTypeMap: {
      Concentrate: {
        subcategories: ['concentrates-oil']
      }
    },
    liquidEdibleTypes: {
      categories: ['Topicals'],
      subcategories: ['drinks', 'concentrates-oil']
    }
  },
  defaults: {
    Flower: 0,
    Edible: 0,
    Concentrate: 0,
    Vaporizers: 0,
    'Pre-Rolls': 0,
    Tincture: 0,
    Topicals: 0,
    Seeds: 0,
    CBD: 0
  },
  restrictions: {
    effects: ['Pain-Relief']
  }
};
export var jamaicanDefaults = {
  medMinAge: 18,
  recMinAge: 18,
  medSubjectToExciseTax: false,
  deliveryAvailable: false,
  oneDeliveryPerDispoPerDay: false,
  residentialDeliveryOnly: false,
  isolatedMenus: false,
  medCardReciprocity: true,
  limits: {
    Flower: {
      medical: 56,
      rec: 56
    },
    message: "Sorry! You've reached the 2oz purchase limit for cannabis due to regulations."
  },
  defaults: {
    Flower: 0,
    Edible: 0,
    Concentrate: 0,
    Vaporizers: 0,
    'Pre-Rolls': 0,
    Tincture: 0,
    Topicals: 0,
    Seeds: 0,
    CBD: 0
  },
  restrictions: {
    effects: []
  }
};
export var saintVincentAndTheGrenadinesDefaults = {
  medMinAge: 18,
  recMinAge: 18,
  medSubjectToExciseTax: false,
  deliveryAvailable: false,
  oneDeliveryPerDispoPerDay: false,
  residentialDeliveryOnly: false,
  isolatedMenus: false,
  medCardReciprocity: true,
  limits: {
    Flower: {
      medical: 56,
      rec: 56
    },
    message: "Sorry! You've reached the 2oz purchase limit for cannabis due to regulations."
  },
  defaults: {
    Flower: 0,
    Edible: 0,
    Concentrate: 0,
    Vaporizers: 0,
    'Pre-Rolls': 0,
    Tincture: 0,
    Topicals: 0,
    Seeds: 0,
    CBD: 0
  },
  restrictions: {
    effects: []
  }
}; // All limits are in grams.
// This should NEVER be accessed directly.
// Use: getComplianceConfig instead.

export var stateComplianceRules = {
  DEFAULT: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    deliveryAvailable: false,
    oneDeliveryPerDispoPerDay: false,
    residentialDeliveryOnly: false,
    isolatedMenus: false,
    // Enforce that a cart only has medical or rec items.
    medCardReciprocity: false,
    baseTincturesOffWeight: false,
    // our defaults for tinctures are based on potency, not weight (g)
    baseEdiblesOffWeight: false,
    // our defaults for edibles are based on potency, not weight (g)
    baseConcentrateOffWeight: true,
    // our defaults for concentrate are based on weight, not potency (g)
    limits: {
      usesEquivalencyCalculator: false,
      Flower: {
        medical: Infinity,
        rec: Infinity
      },
      Concentrate: {
        medical: Infinity,
        rec: Infinity
      }
    },
    // these defaults are manually applied to all the other states by the validating code
    defaults: {
      Concentrate: 0,
      Flower: 0,
      Vaporizers: 0,
      Edible: 100,
      'Pre-Rolls': 1,
      Tincture: 25,
      Topicals: 0,
      Seeds: 0,
      CBD: 0
    },
    restrictions: {
      effects: []
    }
  },
  AK: {
    medMinAge: 18,
    recMinAge: 21,
    limits: {
      usesEquivalencyCalculator: true,
      Flower: {
        medical: 28.35,
        rec: 28.35
      },
      Concentrate: {
        medical: 7,
        rec: 7
      },
      EdibleTHC: {
        medical: 5.6,
        rec: 5.6
      },
      TopicalTHC: {
        medical: 5.6,
        rec: 5.6
      },
      TinctureTHC: {
        medical: 5.6,
        rec: 5.6
      },
      concentrateMultiplier: 4.05,
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers']
        }
      }
    }
  },
  AR: {
    medMinAge: 18,
    recMinAge: 21,
    limits: {
      Flower: {
        medical: 70.87,
        rec: Infinity
      },
      medUsesEquivalencyCalculator: true
    }
  },
  AZ: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    limits: {
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      concentrateMultiplier: 1,
      Flower: {
        medical: 70.0,
        rec: 28.0
      },
      Concentrate: {
        medical: 70.0,
        rec: 5.0
      }
    }
  },
  CA: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: true,
    deliveryAvailable: true,
    limits: {
      Flower: {
        medical: 224.0,
        rec: 28.5
      },
      Concentrate: {
        medical: 224.0,
        rec: 8.0
      },
      typeMap: {
        Concentrate: {
          categories: ['Edible', 'Concentrate', 'Vaporizers', 'Tincture'],
          subcategories: ['infused']
        }
      }
    }
  },
  CO: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    isolatedMenus: true,
    limits: {
      Flower: {
        medical: 56.7,
        rec: 28.35
      },
      Concentrate: {
        medical: 8,
        rec: 8
      },
      EdibleTHC: {
        medical: 20,
        rec: 0.8
      },
      TinctureTHC: {
        medical: 20,
        rec: 0.8
      },
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      edibleMultiplier: {
        med: 56.7 / 20,
        rec: 28.35 / 0.8
      },
      tinctureMultiplier: {
        med: 56.7 / 20,
        rec: 3.54
      },
      concentrateMultiplier: {
        med: 56.7 / 8,
        rec: 3.54
      },
      ignoreTypeMap: {
        Flower: {
          subcategories: ['infused']
        }
      },
      typeMap: {
        Concentrate: {
          categories: ['Concentrate'],
          subcategories: ['infused']
        }
      },
      message: 'Sorry! You have reached the cannabis purchasing limit due to state regulations.'
    }
  },
  CT: {
    medMinAge: 18,
    recMinAge: 21,
    limits: {
      medUsesEquivalencyCalculator: true,
      usesEquivalencyCalculator: true,
      Flower: {
        medical: 140,
        rec: 14.1748
      },
      message: 'Sorry! You’ve reached the 0.5oz purchase limit or equivalent for cannabis due to state regulations.'
    }
  },
  DC: {
    limits: {
      Flower: {
        medical: 224,
        rec: 112
      }
    }
  },
  DE: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    deliveryAvailable: true,
    limits: {
      Flower: {
        medical: 168.0,
        rec: Infinity
      },
      Concentrate: {
        medical: 168.0,
        rec: Infinity
      },
      EdibleTHC: {
        medical: 168.0,
        rec: Infinity
      },
      medUsesEquivalencyCalculator: true,
      edibleMultiplier: 1,
      concentrateMultiplier: 1,
      message: "Sorry! You've reached the 6oz purchase limit for cannabis due to state regulations."
    }
  },
  FL: {
    filterSubcategories: {
      Edible: ['capsules-tablets']
    }
  },
  HI: {
    limits: {
      Flower: {
        medical: 113,
        rec: Infinity
      },
      concentrateMultiplier: 1,
      edibleMultiplier: 1,
      topicalMultiplier: 1,
      tinctureMultiplier: 1,
      medUsesEquivalencyCalculator: true,
      message: "Sorry! You've reached the 4oz purchase limit for cannabis due to state regulations."
    }
  },
  IL: {
    medMinAge: 18,
    recMinAge: 21,
    limits: {
      Flower: {
        medical: 70,
        rec: 30
      },
      Concentrate: {
        medical: 70,
        rec: 5
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 0.5
      },
      usesEquivalencyCalculator: false,
      medUsesEquivalencyCalculator: true,
      concentrateMultiplier: 1,
      edibleMultiplier: 1,
      ignoreStandardEquivalentField: true
    },
    outOfStateLimits: {
      Flower: {
        medical: 70,
        rec: 15
      },
      Concentrate: {
        medical: 70,
        rec: 2.5
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 0.25
      },
      usesEquivalencyCalculator: false,
      medUsesEquivalencyCalculator: true,
      concentrateMultiplier: 1,
      edibleMultiplier: 1,
      ignoreStandardEquivalentField: true
    }
  },
  MA: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    limits: {
      Flower: {
        medical: Infinity,
        rec: 28.35
      },
      Concentrate: {
        medical: Infinity,
        rec: 5.0
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 0.5
      },
      TinctureTHC: {
        medical: Infinity,
        rec: 5.0
      },
      usesEquivalencyCalculator: true,
      edibleMultiplier: 56.7,
      tinctureMultiplier: 5.67,
      concentrateMultiplier: 5.67,
      baseConcentrateOffWeight: false,
      typeMap: {
        Concentrate: {
          subcategories: ['infused', 'infused-bud']
        }
      }
    },
    defaults: {
      Flower: 0,
      'Pre-Rolls': 1.0,
      Edible: 100,
      Concentrate: 1,
      Tincture: 100,
      Topicals: 0,
      Vaporizers: 0,
      Seeds: 0,
      CBD: 0
    }
  },
  MD: {
    limits: {
      Flower: {
        medical: Infinity,
        rec: 42
      },
      Concentrate: {
        medical: Infinity,
        rec: 11.88
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 0.7425
      },
      TinctureTHC: {
        medical: Infinity,
        rec: 0.7425
      },
      TopicalTHC: {
        medical: Infinity,
        rec: 0.7425
      },
      usesEquivalencyCalculator: false,
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers']
        }
      },
      message: 'Sorry! You’ve reached the 1.5 oz purchase limit for cannabis due to state regulations.'
    }
  },
  ME: {
    medMinAge: 18,
    recMinAge: 21,
    medCardReciprocity: true,
    limitedStateReciprocity: true,
    medCardLimitedReciprocity: ['AK', 'AZ', 'AR', 'CA', 'CT', 'FL', 'HI', 'IA', 'IL', 'MA', 'MD', 'ME', 'MI', 'MN', 'MT', 'NV', 'NH', 'NJ', 'NM', 'NY', 'ND', 'OK', 'OR', 'RI', 'VT', 'WA', 'DC'],
    limits: {
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      concentrateMultiplier: 1,
      Flower: {
        medical: 70,
        rec: 70
      },
      Concentrate: {
        medical: 70,
        rec: 10
      }
    }
  },
  MI: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    deliveryAvailable: true,
    residentialDeliveryOnly: true,
    medCardReciprocity: true,
    limits: {
      Flower: {
        medical: 70.0,
        rec: 70.0
      },
      Concentrate: {
        medical: 70.0,
        rec: 15.0
      },
      SolidEdibleNetWeight: {
        medical: 1133.98,
        rec: 1133.98
      },
      LiquidEdibleVolumeInOunces: {
        medical: 90,
        rec: 90
      },
      concentrateMultiplier: {
        med: 1,
        rec: 70.0 / 15.0
      },
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      usesSolidEdibleTypes: true,
      usesLiquidTypes: true,
      solidNetWeightMultiplier: {
        med: 70.0 / 1133.98,
        rec: 70.0 / 1133.98
      },
      liquidNetWeightMultiplier: {
        med: 70.0 / 2551.0,
        rec: 70.0 / 2551.0
      },
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers'],
          subcategories: ['infused', 'infused-bud']
        }
      },
      liquidEdibleTypes: {
        categories: ['Topicals', 'Tincture'],
        subcategories: ['drinks']
      }
    }
  },
  MO: {
    limits: {
      Flower: {
        medical: 168,
        rec: 84
      },
      Concentrate: {
        medical: 48,
        rec: 24
      },
      EdibleTHC: {
        medical: 4.8,
        rec: 2.4
      },
      TinctureTHC: {
        medical: 4.8,
        rec: 2.4
      },
      TopicalTHC: {
        medical: 4.8,
        rec: 2.4
      },
      concentrateMultiplier: 3.5,
      edibleMultiplier: 35,
      tinctureMultiplier: 35,
      topicalMultiplier: 35,
      medUsesEquivalencyCalculator: true,
      usesEquivalencyCalculator: true
    }
  },
  MS: {
    // Note: Mississippi is med ONLY and rec limits only exist as potential fallback
    limits: {
      Flower: {
        medical: 84,
        rec: Infinity
      },
      Concentrate: {
        medical: 24,
        rec: Infinity
      },
      EdibleTHC: {
        medical: 2.4,
        rec: Infinity
      },
      TinctureTHC: {
        medical: 2.4,
        rec: Infinity
      },
      TopicalTHC: {
        medical: 2.4,
        rec: Infinity
      },
      medUsesEquivalencyCalculator: true,
      concentrateMultiplier: 3.5,
      edibleMultiplier: 35,
      tinctureMultiplier: 35,
      topicalMultiplier: 35,
      typeMap: {
        Flower: {
          categories: ['Pre-Rolls']
        },
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers']
        }
      }
    }
  },
  MT: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: true,
    deliveryAvailable: true,
    limits: {
      Flower: {
        medical: 28,
        rec: 28
      },
      Concentrate: {
        medical: 8,
        rec: 8
      },
      EdibleTHC: {
        medical: 0.8,
        rec: 0.8
      },
      TopicalTHC: {
        medical: 0.8,
        rec: 0.8
      },
      TinctureTHC: {
        medical: 0.8,
        rec: 0.8
      },
      medUsesEquivalencyCalculator: true,
      usesEquivalencyCalculator: true,
      concentrateMultiplier: 3.5,
      tinctureMultiplier: 35,
      edibleMultiplier: 35,
      topicalMultiplier: 35,
      typeMap: {
        Edible: {
          categories: ['Edible', 'Topicals']
        }
      }
    }
  },
  ND: {
    limits: {
      Flower: {
        medical: 70.87,
        rec: 0
      },
      // non-flower limits for concentrates, solutions, capsules, transdermal, topicals
      nonFlower: {
        medical: 6,
        rec: 0
      }
    }
  },
  NH: {
    medCardReciprocity: true,
    limits: {
      medUsesEquivalencyCalculator: true,
      usesEquivalencyCalculator: true,
      Flower: {
        medical: 56.0,
        rec: 56.0
      },
      concentrateMultiplier: 4,
      edibleMultiplier: 4,
      tinctureMultiplier: 4,
      topicalMultiplier: 4,
      message: "Sorry! You've reached the 2oz purchase limit for cannabis due to state regulations."
    }
  },
  NJ: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    limits: {
      Flower: {
        medical: 84.0,
        rec: 28.35
      },
      Concentrate: {
        medical: 84.0,
        rec: 5.0
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 1.0
      },
      TinctureTHC: {
        medical: Infinity,
        rec: 1.0
      },
      concentrateMultiplier: 5.67,
      edibleMultiplier: 56.7,
      tinctureMultiplier: 56.7,
      usesEquivalencyCalculator: true,
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers']
        }
      },
      message: "Sorry! You've reached the 1oz purchase limit for cannabis due to state regulations."
    }
  },
  NM: {
    limits: {
      Flower: {
        medical: Infinity,
        rec: 56.7
      },
      Concentrate: {
        medical: Infinity,
        rec: 16
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 0.8
      },
      ignoreStandardEquivalentField: true
    }
  },
  NV: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    deliveryAvailable: true,
    residentialDeliveryOnly: true,
    medCardReciprocity: true,
    limits: {
      Flower: {
        medical: 70.875,
        rec: 70.875
      },
      Concentrate: {
        medical: 7.086,
        rec: 7.086
      },
      TopicalTHC: {
        medical: 7.086,
        rec: 7.086
      },
      EdibleTHC: {
        medical: 7.086,
        rec: 7.086
      },
      TinctureTHC: {
        medical: 7.086,
        rec: 7.086
      },
      baseConcentrateOffWeight: false,
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Edible', 'Topicals', 'Tincture', 'Vaporizers'],
          subcategories: ['infused']
        }
      },
      ignoreTypeMap: {
        Flower: {
          subcategories: ['infused']
        }
      },
      concentrateMultiplier: {
        med: 10.002,
        rec: 10.002
      },
      edibleMultiplier: {
        med: 10.002,
        rec: 10.002
      },
      tinctureMultiplier: {
        med: 10.002,
        rec: 10.002
      },
      topicalMultiplier: {
        med: 10.002,
        rec: 10.002
      },
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      message: 'Sorry! You’ve reached the 2.5oz or equivalent purchase limit for cannabis due to state regulations.'
    }
  },
  NY: {
    medMinAge: 18,
    recMinAge: 21,
    limits: {
      Flower: {
        medical: Infinity,
        rec: 85
      },
      Concentrate: {
        medical: Infinity,
        rec: 24
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 24
      },
      TinctureTHC: {
        medical: Infinity,
        rec: 24
      },
      usesEquivalencyCalculator: false,
      edibleMultiplier: 3.54,
      tinctureMultiplier: 3.54,
      concentrateMultiplier: 3.54,
      baseTincturesOffWeight: true,
      baseEdiblesOffWeight: true,
      typeMap: {
        Flower: {
          categories: ['Flower', 'Pre-rolls']
        },
        Concentrate: {
          categories: ['Concentrate', 'Edible', 'Topicals', 'Tincture', 'Vaporizers']
        }
      }
    }
  },
  OH: {
    recMinAge: 21,
    limits: {
      Flower: {
        medical: Infinity,
        rec: 28.3
      },
      Concentrate: {
        medical: Infinity,
        rec: 5.9
      },
      EdibleTHC: {
        medical: Infinity,
        rec: 1.1
      },
      usesEquivalencyCalculator: true,
      concentrateMultiplier: 28.3 / 5.9,
      edibleMultiplier: 28.3 / 1.1
    },
    typeMap: {
      Concentrate: {
        subcategories: ['infused']
      }
    }
  },
  OR: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: false,
    deliveryAvailable: true,
    oneDeliveryPerDispoPerDay: true,
    residentialDeliveryOnly: true,
    limits: {
      SolidEdibleNetWeight: {
        medical: 448,
        rec: 448
      },
      // Liquid volume limits are all defined in OUNCES
      LiquidEdibleVolumeInOunces: {
        medical: 72,
        rec: 72
      },
      Flower: {
        medical: 224.0,
        rec: 56.0
      },
      Concentrate: {
        medical: 10.0,
        rec: 10.0
      },
      typeMap: {
        Concentrate: {
          subcategories: ['infused']
        }
      },
      liquidEdibleTypes: {
        categories: ['Tincture', 'Topicals'],
        subcategories: ['drinks']
      },
      ignoreStandardEquivalentField: true
    }
  },
  OK: {
    limits: {
      Flower: {
        medical: 84.9,
        rec: 84.9
      },
      Concentrate: {
        medical: 28.3,
        rec: 28.3
      },
      EdibleTHC: {
        medical: 2037.6,
        rec: 2037.6
      }
    }
  },
  RI: {
    medMinAge: 18,
    recMinAge: 21,
    medCardReciprocity: true,
    limits: {
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      Flower: {
        medical: 70,
        rec: 28
      },
      Concentrate: {
        medical: 19.25,
        rec: 7.7
      },
      EdibleTHC: {
        medical: 1.25,
        rec: 0.83
      },
      TinctureTHC: {
        medical: 1.25,
        rec: 0.83
      },
      TopicalTHC: {
        medical: 1.25,
        rec: 0.83
      },
      concentrateMultiplier: {
        med: 3.63,
        rec: 3.63
      },
      edibleMultiplier: {
        med: 56,
        rec: 33.7
      },
      tinctureMultiplier: {
        med: 56,
        rec: 33.7
      },
      topicalMultiplier: {
        med: 56,
        rec: 33.7
      }
    }
  },
  SD: {
    limits: {
      Flower: {
        medical: 85.02,
        rec: 85.02
      },
      Concentrate: {
        medical: 24,
        rec: 24
      },
      TinctureTHC: {
        medical: 45,
        rec: 45
      },
      EdibleTHC: {
        medical: 6,
        rec: 6
      },
      TopicalTHC: {
        medical: 2.4,
        rec: 2.4
      },
      usesLiquidTypes: true,
      LiquidEdibleVolumeInOunces: {
        medical: 36,
        rec: 36
      },
      liquidNetWeightMultiplier: 85.02 / 1064.65,
      liquidEdibleTypes: {
        subcategories: ['lotions', 'oils', 'lotions', 'sticks-roll-ons', 'bath-products', 'lip-balms', 'soaps', 'lubricants', 'balms']
      },
      topicalMultiplier: 35.43,
      tinctureMultiplier: 1.89,
      concentrateMultiplier: 3.54,
      edibleMultiplier: 14.17,
      baseConcentrateOffWeight: true,
      baseTincturesOffWeight: true,
      usesEquivalencyCalculator: true,
      medUsesEquivalencyCalculator: true,
      ignoreTypeMap: {
        Topicals: {
          subcategories: ['lotions', 'oils', 'lotions', 'sticks-roll-ons', 'bath-products', 'lip-balms', 'soaps', 'lubricants', 'balms']
        }
      }
    }
  },
  VT: {
    limits: {
      Flower: {
        medical: Infinity,
        rec: 28
      }
    }
  },
  WA: {
    medMinAge: 18,
    recMinAge: 21,
    medSubjectToExciseTax: true,
    limits: {
      SolidEdibleNetWeight: {
        medical: 1344,
        rec: 448
      },
      // Liquid volume limits are all defined in OUNCES
      LiquidEdibleVolumeInOunces: {
        medical: 216,
        rec: 72
      },
      Flower: {
        medical: 84.0,
        rec: 28.0
      },
      Concentrate: {
        medical: 21.0,
        rec: 7.0
      },
      typeMap: {
        Concentrate: {
          subcategories: ['infused']
        }
      },
      ignoreTypeMap: {
        Flower: {
          subcategories: ['infused']
        }
      },
      liquidEdibleTypes: {
        subcategories: ['drinks']
      }
    }
  },
  AB: _objectSpread(_objectSpread({}, canadaDefaults), {}, {
    recMinAge: 18
  }),
  BC: _objectSpread(_objectSpread({}, canadaDefaults), {}, {
    limits: _objectSpread(_objectSpread({}, canadaDefaults.limits), {}, {
      Concentrate: {
        medical: Infinity,
        rec: 30
      },
      SolidEdibleNetWeight: {
        medical: Infinity,
        rec: 30
      },
      LiquidEdibleVolumeInOunces: {
        medical: Infinity,
        rec: 30
      },
      message: "Sorry! You've reached the 30g purchase limit for cannabis due to federal regulations."
    })
  }),
  ON: {
    // This config specific to Ontario may be redundant
    medMinAge: 18,
    recMinAge: 19,
    limits: {
      Flower: {
        medical: Infinity,
        rec: 30.0
      },
      Concentrate: {
        medical: Infinity,
        rec: 7.5
      },
      TinctureTHC: {
        medical: Infinity,
        rec: 7.5
      },
      SolidEdibleNetWeight: {
        medical: Infinity,
        rec: 450
      },
      LiquidEdibleVolumeInOunces: {
        medical: Infinity,
        rec: 578.2198
      },
      concentrateMultiplier: 4,
      tinctureMultiplier: 4,
      solidNetWeightMultiplier: 30 / 450,
      liquidNetWeightMultiplier: 30 / 2100,
      // Although liquid limits are in ounces this is set in terms of grams
      topicalMultiplier: 0.0143,
      usesEquivalencyCalculator: true,
      usesLiquidTypes: true,
      usesSolidEdibleTypes: true,
      typeMap: {
        Concentrate: {
          categories: ['Concentrate', 'Vaporizers']
        }
      },
      ignoreTypeMap: {
        Concentrate: {
          subcategories: ['concentrates-oil']
        }
      },
      liquidEdibleTypes: {
        categories: ['Topicals'],
        subcategories: ['drinks', 'concentrates-oil']
      }
    }
  },
  Cidra: {
    medCardReciprocity: true,
    limitedStateReciprocity: true,
    medCardLimitedReciprocity: ['PR', 'Cidra']
  },
  Humacao: {
    medCardReciprocity: true,
    limitedStateReciprocity: true,
    medCardLimitedReciprocity: ['PR', 'Humacao']
  },
  Naguabo: {
    medCardReciprocity: true,
    limitedStateReciprocity: true,
    medCardLimitedReciprocity: ['PR', 'Naguabo']
  },
  'San Juan': {
    medCardReciprocity: true,
    limitedStateReciprocity: true,
    medCardLimitedReciprocity: ['PR', 'San Juan']
  }
};
export var statesWithOralsCategory = ['FL', 'NY', 'WV', 'PA'];