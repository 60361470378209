import { useStores } from 'src/state/';

type UseSuperAdminOrBrandAdminHook = () => boolean;

const useSuperAdminOrBrandAdmin: UseSuperAdminOrBrandAdminHook = () => {
  const { user } = useStores();
  return user.isSuperAdmin || user.isBrandAdmin;
};

export default useSuperAdminOrBrandAdmin;
