import React from 'react';
import styled from 'styled-components';
import { top, left, right, bottom, height, width, margin, padding } from 'styled-system';
import _ from 'lodash';

import TooltipIcon from './tooltip-icon';

type IconContainerProps = { iconOpacity?: number };
const IconContainer = styled.div`
  /* display: block; */
  /* width: 18px; */
  height: 15px;
  opacity: ${(props: IconContainerProps) => props.iconOpacity};
`;

type TooltipContainerProps = { containerWidth?: string };
const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${(props: TooltipContainerProps) => props.containerWidth ?? 'fit-content'};
  ${margin}
  ${padding}
`;

type StyledTooltipProps = {
  arrowRight?: boolean;
  bubblePadding?: boolean;
  leftArrow?: boolean;
  mobilePosition?: Record<string, string>;
};
const StyledTooltip = styled(({ ...props }) => <div {...props} />)`
  ${({ mobilePosition }: StyledTooltipProps) =>
    mobilePosition?.right &&
    `
    @media screen and (max-width: 410px) {
      right: ${mobilePosition.right};
    }
  `}

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  font-size: 12px;
  line-height: 16px;
  position: ${({ position }) => position};
  bottom: 30px;
  background-color: rgba(44, 47, 50, 0.92);
  color: #f5f7fa;
  display: block;
  padding: ${(props) => props.bubblePadding || '15px 18px 16px 21px'};
  ${margin}
  white-space: normal;
  font-weight: normal;
  text-align: left;
  border-radius: 10px;
  z-index: 100;
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${height}
  ${width}
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid rgba(44, 47, 50, 0.92);
    position: absolute;
    transform: ${(props) => (props.leftArrow ? 'rotate(90deg) translateX(25%)' : 'none')};
    transform-origin: ${(props) => (props.leftArrow ? 'center' : 'top left')};
    bottom: ${(props) => (props.leftArrow ? '50%' : '-7px')};
    left: ${(props) => (props.leftArrow ? '-11px' : 'auto')};
    right: ${(props) => {
      if (props.centerArrow) {
        return 'calc(50% - 8px)';
      }
      if (props.arrowRight) {
        return props.arrowRight;
      }

      return '61px';
    }};
  }
`;

type ToolTipProps = IconContainerProps &
  StyledTooltipProps &
  TooltipContainerProps & {
    allowHoverAction?: boolean;
    bottom?: string;
    centerArrow?: boolean;
    height?: string;
    hideIcon?: boolean;
    hoverMain?: boolean;
    icon?: string;
    left?: string;
    mainCopy: React.ReactElement;
    margin?: string;
    position?: string;
    right?: string;
    show?: boolean;
    tooltipCopy?: React.ReactElement | string;
    tooltipLink?: string;
    top?: string;
    visible?: boolean;
    width?: string;
    grey?: boolean;
  };

type ToolTipState = {
  hover: boolean;
};

export class Tooltip extends React.Component<ToolTipProps, ToolTipState> {
  constructor(props: ToolTipProps) {
    super(props);
    this.state = {
      hover: Boolean(props.show),
    };
  }

  render(): JSX.Element {
    const {
      mainCopy,
      tooltipCopy,
      hoverMain,
      centerArrow,
      tooltipLink,
      hideIcon,
      icon,
      iconOpacity = 1,
      allowHoverAction = true,
      top: topProp,
      left: leftProp,
      right: rightProp,
      bottom: bottomProp,
      height: heightProp,
      width: widthProp,
      visible,
      position,
      margin: marginProp,
      mobilePosition,
      ...props
    } = this.props;
    const { hover } = this.state;
    return (
      <TooltipContainer {...props}>
        {hoverMain &&
          React.cloneElement(mainCopy, {
            onMouseEnter: () => (allowHoverAction ? this.setState({ hover: true }) : _.noop()),
            onMouseLeave: () => (allowHoverAction ? this.setState({ hover: false }) : _.noop()),
          })}
        {!hoverMain && mainCopy}
        {!hoverMain && !tooltipLink && !hideIcon && (
          <IconContainer
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            iconOpacity={iconOpacity}
          >
            <TooltipIcon color={props.grey ? 'grey' : ''} {...icon} />
          </IconContainer>
        )}
        {!hoverMain && tooltipLink && (
          <IconContainer onClick={() => window.open(tooltipLink, '_blank')} iconOpacity={iconOpacity}>
            <TooltipIcon color={props.grey ? 'grey' : ''} {...icon} />
          </IconContainer>
        )}
        {tooltipCopy && (
          <StyledTooltip
            className={(hover || visible) && 'visible'}
            top={topProp ?? 'auto'}
            left={leftProp ?? 'auto'}
            right={rightProp ?? '-61px'}
            bottom={bottomProp}
            height={heightProp ?? 'auto'}
            width={widthProp ?? '239px'}
            centerArrow={centerArrow}
            arrowRight={props.arrowRight}
            bubblePadding={props.bubblePadding}
            leftArrow={props.leftArrow}
            position={position ?? 'absolute'}
            margin={marginProp ?? 'unset'}
            mobilePosition={mobilePosition}
          >
            <p className='tooltip-copy'>{tooltipCopy}</p>
          </StyledTooltip>
        )}
      </TooltipContainer>
    );
  }
}

export default Tooltip;
