import React from 'react';
import { layout, LayoutProps, width as styledWidth, WidthProps, SpaceProps } from 'styled-system';
import styled, { css, CSSProperties } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextInput } from './text-input';

const UnitSpan = styled.span<{ disabled: boolean }>`
  &&& {
    ${({ disabled }) =>
      disabled &&
      `
      color: #717478;
      opacity: 0.5;
    `}
  }
`;

const SettingsTextInput = styled(TextInput)<{ disabled: boolean }>`
  max-width: 300px;
  margin: 0;
  height: 45px;

  &&& {
    ${({ disabled }) =>
      disabled &&
      `
      color: #717478;
      opacity: 0.5;
      border: 1px solid #d2d5da;
    `}
  }
`;

type InputWithUnitContainerProps = LayoutProps & {
  hasLabel: boolean;
  marginLeft?: CSSProperties['marginLeft'];
};

const InputWithUnitContainer = styled.div<InputWithUnitContainerProps>`
  display: inline-block;
  position: relative;
  ${layout}

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #edf1f3;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    color: #6d747b;
    font-size: 18px;
    height: 43px;
    pointer-events: none;
    position: absolute;
    top: 1px;
    width: 2.25em;
    z-index: 4;
    margin-left: 1px;
    &.left {
      left: 0;
    }
    &.right {
      right: 1px;
    }
    &.ifap {
      margin-left: 10px;
    }
    &.specials {
      ${({ hasLabel }) => (hasLabel ? `padding-top: 10px !important` : null)};
      background-color: transparent;
      font-size: 13px;
      top: 10px;
      padding: 0;
      text-align: left;

      &.left {
        left: 4px;
        text-align: center;
      }
    }
  }

  input {
    &.left {
      padding-left: 61px;
      &.specials {
        background-color: #fff;
        font-size: 13px;
        padding: 13px 15px;
        padding-left: 25px;
        text-align: right;
        margin-right: 6px !important;
        border-radius: 3px;
        height: 37px;
        ${({ hasLabel }) => (hasLabel ? `padding-top: 23px !important` : null)};
      }
    }
    &.right {
      padding-right: 61px;
      &.specials {
        background-color: #fff;
        font-size: 13px;
        padding: 13px 15px;
        width: 84px !important; /* want to allow up 5 digits (000.00) in width */
        border-radius: 3px;
        margin-left: ${({ marginLeft }) => `${marginLeft ?? ''} !important`};
        height: 37px;
        ${({ hasLabel }) => (hasLabel ? `padding-top: 23px !important` : null)};
      }
    }
  }
`;

type TextInputWithUnitProps = SpaceProps &
  WidthProps & {
    label?: string;
    unit: string;
    side: string;
    height?: CSSProperties['height'];
    marginRight?: CSSProperties['marginRight'];
    marginLeft?: CSSProperties['marginLeft'];
    disabled?: boolean;
    name?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
export const TextInputWithUnit = ({
  label,
  unit,
  side = 'left',
  width,
  disabled = false,
  ...props
}: TextInputWithUnitProps): JSX.Element => (
  <StyledInputWithUnit hasLabel={!!label} width={width} {...props}>
    <FormControl variant='filled' classes={{ root: `mui-dt-root` }}>
      {label && (
        <InputLabel
          data-cy='text-input-with-unit-label'
          data-test='text-input-with-unit-label'
          disabled={disabled}
          classes={{
            root: `mui-dt-label-root`,
            focused: `mui-dt-label-focused`,
            error: `mui-dt-label-error`,
            shrink: `mui-dt-label-shrink`,
          }}
        >
          {label}
        </InputLabel>
      )}
      <InputWithUnitContainer hasLabel={!!label} width={width}>
        <UnitSpan className={side} disabled={disabled}>
          {unit}
        </UnitSpan>
        <SettingsTextInput disabled={disabled} className={side} name={label} {...props} />
      </InputWithUnitContainer>
    </FormControl>
  </StyledInputWithUnit>
);

export default TextInputWithUnit;

const labelStyles = css`
  color: #868788 !important;
  .mui-dt-label-root {
    font-weight: normal;
    text-transform: capitalize;
    margin-left: 6px;
    font-size: 10px;
    top: 2px !important;
    animation: unset;
    &.mui-dt-label-shrink {
      transform: translate(20px, 10px);
      font-size: 10px;
    }
    &.MuiInputLabel-animated {
      transition: unset !important;
    }
    &.MuiInputLabel-filled {
      transform: translate(10px, 6px) scale(1);
      color: #868788 !important;
    }
  }

  &.right {
    .mui-dt-label-root {
      &.MuiInputLabel-filled {
        transform: translate(15px, 6px) scale(1);
      }
    }
  }
`;

type StyledInputWithUnitProps = LayoutProps & {
  hasLabel: boolean;
  marginRight?: CSSProperties['marginRight'];
  marginLeft?: CSSProperties['marginLeft'];
};
const StyledInputWithUnit = styled.div<StyledInputWithUnitProps>`
  ${styledWidth}
  ${labelStyles}

  .Mui-disabled {
    cursor: not-allowed;
  }

  .mui-dt-input-root {
    height: ${({ height }) => height ?? '45px'};
    background-color: #fcfdfe;
    border: 1px solid #d2d5da;
    color: #717478 !important;
    border-radius: 3px !important;

    &.Mui-focused,
    &:hover {
      border: 1px solid #b6b6b6;
    }
  }

  input.specials {
    font-size: 13px;
    padding: ${({ hasLabel }) => `${hasLabel ? 15 : 12}px 16px 6px 16px`};
    height: 45px !important;
    width: 100% !important;
    border-radius: 3px !important;

    &:focus {
      background-color: transparent;
    }

    &.right {
      margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
      margin-right: ${({ marginRight }) => marginRight ?? '0px'};
    }
  }
`;
