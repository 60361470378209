import React, { useState } from 'react';
import { observer } from 'mobx-react';

import ModalContainer from 'src/shared/modals/container';
import ModalSecondaryHeader from 'src/shared/modals/header';
import ModalClose from 'src/shared/modals/close';
import ModalCopy from 'src/shared/modals/copy';
import ModalButton from 'src/shared/modals/button';
import { ElementContainer } from 'src/shared/modals/util';

import { useStores } from 'src/state';
import { ModalTypes } from 'src/state/ui';

export type ConfirmModalProps = {
  modalTitle: string;
  confirmActionText: string;
  cancelActionText: string;
  modalCopy: string;
  onConfirm: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
};

const ConfirmModal = (): JSX.Element => {
  const { ui } = useStores();

  const [loading, setLoading] = useState(false);

  const handleCancel = async (): Promise<void> => {
    void setLoading(true);
    await ui.confirmModalProps.onCancel();
    void ui.toggleModal(ModalTypes.confirmation);
    void setLoading(false);
  };

  const handleConfirm = async (): Promise<void> => {
    void setLoading(true);
    await ui.confirmModalProps.onConfirm();
    void ui.toggleModal(ModalTypes.confirmation);
    void setLoading(false);
  };

  return (
    <ModalContainer
      data-cy='confirm-modal'
      data-test='confirm-modal'
      isOpen={ui.modalStates.confirmation}
      onRequestClose={() => undefined}
      width='495px'
      height='335px'
      p='31px 47px 20px 47px'
      important
    >
      <ModalSecondaryHeader lowercase>{ui.confirmModalProps.modalTitle}</ModalSecondaryHeader>
      <ModalCopy>{ui.confirmModalProps.modalCopy}</ModalCopy>
      <ElementContainer>
        <ModalButton
          aria-label='confirm modal confirm button'
          data-cy='confirm-modal-confirm'
          data-test='confirm-modal-confirm'
          width='90%'
          mt='18px'
          mb={[0]}
          flexShrink='2'
          onClick={handleConfirm}
          loading={loading}
        >
          {ui.confirmModalProps.confirmActionText}
        </ModalButton>
        <ModalButton
          aria-label='confirm modal cancel button'
          data-cy='confirm-modal-cancel'
          data-test='confirm-modal-cancel'
          width='90%'
          mt='18px'
          inverted
          flexShrink='4'
          onClick={handleCancel}
          disabled={loading}
        >
          {ui.confirmModalProps.cancelActionText}
        </ModalButton>
      </ElementContainer>
      <ModalClose disabled={loading} onClick={handleCancel} />
    </ModalContainer>
  );
};

export default observer(ConfirmModal);
