import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'src/shared/styles';
import { AppRoutes } from 'src/utils/constants';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useStores } from 'src/state';

export function SidebarFooter(): JSX.Element {
  const router = useRouter();
  const { user } = useStores();
  const { t } = useTranslation();

  const handleLogout: () => void = () => {
    void user.logout();
    void router.push(AppRoutes.login());
  };

  return (
    <Container>
      <HeadWayContainer id='headway'>
        <Image src='/icons/dutchie-wordmark-white.svg' alt='Dutchie Logo' />
      </HeadWayContainer>

      <Section>
        <StyledLink href='mailto:brandsupport@dutchie.com'>{t('links.dutchieSupport', 'Support')}</StyledLink>
        <SpacingSpan>|</SpacingSpan>
        <StyledLink href='https://status.dutchie.com' target='_blank'>
          {t('links.dutchieStatus', 'Status')}
        </StyledLink>
        <SpacingSpan>|</SpacingSpan>
        <StyledLink onClick={handleLogout}>Logout</StyledLink>
      </Section>
    </Container>
  );
}

const HeadWayContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  margin-bottom: 22px;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 67px;

  @media ${mediaQueries.desktop} {
    width: 58px;
  }
`;

const Section = styled.div`
  font-size: 12px;
  margin-top: 11px;
  text-align: center;
  span {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.4;
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.9;
  cursor: pointer;

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.6;
    text-decoration: none;
  }
`;

const SpacingSpan = styled.span`
  margin: 0 5px;
`;
