export var AdvancedConditionType;

(function (AdvancedConditionType) {
  AdvancedConditionType["totalQuantity"] = "totalQuantity";
  AdvancedConditionType["totalSpend"] = "totalSpend";
  AdvancedConditionType["totalWeight"] = "totalWeight";
})(AdvancedConditionType || (AdvancedConditionType = {}));

export var AdvancedRewardType;

(function (AdvancedRewardType) {
  AdvancedRewardType["discountToCart"] = "discountToCart";
  AdvancedRewardType["itemsForADiscount"] = "itemsForADiscount";
  AdvancedRewardType["itemsForAPrice"] = "itemsForAPrice";
})(AdvancedRewardType || (AdvancedRewardType = {}));

export var BasicConditionOrRewardType;

(function (BasicConditionOrRewardType) {
  BasicConditionOrRewardType["activeBatchTag"] = "inventoryTag";
  BasicConditionOrRewardType["brand"] = "brand";
  BasicConditionOrRewardType["category"] = "category";
  BasicConditionOrRewardType["individual"] = "individual";
  BasicConditionOrRewardType["productTag"] = "productTag";
  BasicConditionOrRewardType["strain"] = "strain";
  BasicConditionOrRewardType["vendor"] = "vendor";
})(BasicConditionOrRewardType || (BasicConditionOrRewardType = {}));