import React, { useState } from 'react';
import { reaction, IReactionOptions } from 'mobx';
import { observer } from 'mobx-react';
import { useStores } from 'src/state';
import { Container, IconContainer, Message, ErnieAlertType } from 'src/shared/components/ernie/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';

const ErrorIcon = (): JSX.Element => <FontAwesomeIcon icon={faTimes} />;
const SuccessIcon = (): JSX.Element => <FontAwesomeIcon icon={faCheck} />;
const InfoIcon = (): JSX.Element => <FontAwesomeIcon icon={faInfo} />;

const iconMapping: Record<ErnieAlertType, () => JSX.Element> = {
  danger: ErrorIcon,
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
};

type ErnieProps = {
  isIframe?: boolean;
  iframeOffset?: number;
  parentOffset?: number;
};

function useReaction(expression: () => any, effect: () => void, options?: IReactionOptions<any>): void {
  React.useEffect(() => reaction(expression, effect, options), [effect, expression, options]);
}

function Ernie(props: ErnieProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState('');
  const [type, setType] = useState<ErnieAlertType>('danger');
  const { ui } = useStores();

  useReaction(
    () => ui.ernieQueue.length,
    () => show()
  );

  const show = (): void => {
    const itemsInQueue = ui.ernieQueue.length !== 0;

    if (itemsInQueue && !visible) {
      const currentAlert = ui.ernieQueue.shift();

      // Makes typescript happy, shouldn't really be possible
      // outside of bugs / race conditions
      if (!currentAlert) {
        throw Error('Ernie queue was shifted before we could read the item');
      }

      setMsg(currentAlert.msg);
      setType(currentAlert.type);
      setVisible(true);

      setTimeout(() => {
        const itemsStillInQueue = ui.ernieQueue.length !== 0;

        // clear message to ensure next alert is read by screen readers
        setVisible(false);
        setMsg('');

        if (itemsStillInQueue) {
          setTimeout(() => show(), 250);
        }
      }, currentAlert.timeout);
    }
  };

  const { isIframe, iframeOffset, parentOffset } = props;
  const IconComponent = iconMapping[type];

  return (
    <Container
      data-cy='ernie-container'
      data-test='ernie-container'
      type={type}
      visible={visible}
      isIframe={isIframe ?? false}
      iframeOffset={iframeOffset ?? 0}
      parentOffset={parentOffset ?? 0}
      role='region'
      aria-live='assertive'
    >
      <IconContainer>
        <IconComponent />
      </IconContainer>

      <Message data-cy='ernie-message' data-test='ernie-message'>
        {msg}
      </Message>
    </Container>
  );
}

export default observer(Ernie);
