import _toConsumableArray from "/ecomm-brands/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/ecomm-brands/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _POTENCY_UNITS_DISPLA;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _ from 'lodash';
import deepFreeze from 'deep-freeze'; // https://github.com/pmmmwh/react-refresh-webpack-plugin/blob/main/docs/TROUBLESHOOTING.md#usage-with-indirection-like-workers-and-js-templates

/* eslint-disable no-unused-vars */

var $RefreshReg$ = _.noop;

var $RefreshSig$ = function $RefreshSig$() {
  return _.noop;
};
/* eslint-enable no-unused-vars */
// We've built database indexes around this list.  If it is expanded please consider
// the search consequences.


export var productEffects = ['Calm', 'Clear Mind', 'Creative', 'Energetic', 'Focused', 'Happy', 'Inspired', 'Relaxed', 'Sleepy', 'Uplifted']; // old product effects
// 'Body',
// 'Comfort',
// 'Joints',
// 'Mind',
// 'Muscles',
// 'Pain-Relief',
// 'Skin Health',

export function normalizeEffectName(name) {
  return _.toLower(name).replace(/[-\s]/g, '');
}
export var allFlowerOptions = ['1g', '1.5g', '2g', '1/8oz', '4.5g', '1/4oz', '1/2oz', '1oz'];
export var allVapeOptions = ['.25g', '.3g', '.4g', '.5g', '.6g', '.7g', '.75g', '.8g', '.9g', '1g', '1.25g'];
export var allDabOptions = ['.25gC', '.5gC', '.75gC', '1gC', '1.25gC', '1.5gC', '2gC', '2.25gC', '2.5gC', '2.75gC', '3gC', '3.25gC', '3.5gC'];
export var allPrerollOptions = ['.2g', '.5g', '.6g', '.7g', '.75g', '1g', '1.2g', '1.4g', '1.5g', '2g', '2.25g', '2.5g', '3g', '3.5g', '3.75g', '4.5g'];
export var getAllOptions = function getAllOptions(type) {
  var options;

  switch (type) {
    case 'Flower':
      options = allFlowerOptions;
      break;

    case 'Pre-Rolls':
      options = allPrerollOptions;
      break;

    case 'Vaporizers':
      options = [].concat(allVapeOptions, allDabOptions);
      break;

    case 'Concentrate':
      options = allDabOptions;
      break;

    default:
      options = ['N/A'];
  }

  return options;
};
export var standardVaporizerWeights = {
  pax: 0.5,
  quill: 0.75,
  orchid: 1
};
export var thresholdDefaults = {
  Flower: '5',
  'Pre-Rolls': '5',
  Vaporizers: '5',
  Concentrate: '5',
  Edible: '5',
  Topicals: '5',
  Tincture: '5',
  Accessories: '5',
  Apparel: '5'
};
export var ouncesWeightNames = {
  Flower: {
    '1g': 'Grams',
    1.5: '1.5 Grams',
    '2g': '2 Grams',
    '1/8oz': 'Eighths',
    '1/4oz': 'Quarters',
    '1/2oz': 'Half Ounces',
    '1oz': 'Ounces'
  }
};
export var gramsWeightNames = {
  Flower: {
    '1g': 'Grams',
    1.5: '1.5 Grams',
    '2g': '2 Grams',
    '1/8oz': '3.5 Grams',
    '1/4oz': '7 Grams',
    '1/2oz': '14 Grams',
    '1oz': '28 Grams'
  }
};
export var POTENCY_UNITS = {
  PERCENTAGE: 'PERCENTAGE',
  MILLIGRAMS: 'MILLIGRAMS',
  MILLIGRAMS_PER_GRAM: 'MILLIGRAMS_PER_GRAM',
  MILLIGRAMS_PER_ML: 'MILLIGRAMS_PER_ML'
};
export var POTENCY_UNITS_DISPLAY = (_POTENCY_UNITS_DISPLA = {}, _defineProperty(_POTENCY_UNITS_DISPLA, POTENCY_UNITS.PERCENTAGE, '%'), _defineProperty(_POTENCY_UNITS_DISPLA, POTENCY_UNITS.MILLIGRAMS, 'mg'), _defineProperty(_POTENCY_UNITS_DISPLA, POTENCY_UNITS.MILLIGRAMS_PER_GRAM, 'mg/g'), _defineProperty(_POTENCY_UNITS_DISPLA, POTENCY_UNITS.MILLIGRAMS_PER_ML, 'mg/mL'), _POTENCY_UNITS_DISPLA);
export var POTENCY_TYPES = {
  CBD: 'CBD',
  THC: 'THC',
  TAC: 'TAC'
};
export var POTENCY_UNIT_OPTIONS = [{
  key: POTENCY_UNITS.PERCENTAGE,
  label: POTENCY_UNITS_DISPLAY.PERCENTAGE,
  value: POTENCY_UNITS.PERCENTAGE
}, {
  key: POTENCY_UNITS.MILLIGRAMS,
  label: POTENCY_UNITS_DISPLAY.MILLIGRAMS,
  value: POTENCY_UNITS.MILLIGRAMS
}, {
  key: POTENCY_UNITS.MILLIGRAMS_PER_GRAM,
  label: POTENCY_UNITS_DISPLAY.MILLIGRAMS_PER_GRAM,
  value: POTENCY_UNITS.MILLIGRAMS_PER_GRAM
}, {
  key: POTENCY_UNITS.MILLIGRAMS_PER_ML,
  label: POTENCY_UNITS_DISPLAY.MILLIGRAMS_PER_ML,
  value: POTENCY_UNITS.MILLIGRAMS_PER_ML
}];
export var inhalableCategories = ['Flower', 'Pre-Rolls', 'Concentrate', 'Vaporizers'];
export var carouselProductLimit = 15;
export var SubcategoryOptions = deepFreeze({
  'Pre-Rolls': [{
    key: 'singles',
    label: 'Singles',
    value: 'singles'
  }, {
    key: 'packs',
    label: 'Pre-Roll Packs',
    value: 'packs'
  }, {
    key: 'infused',
    label: 'Infused Pre-Rolls',
    value: 'infused'
  }, {
    key: 'blunts',
    label: 'Blunts',
    value: 'blunts'
  }],
  Vaporizers: [{
    key: 'cartridges',
    label: 'Cartridges',
    value: 'cartridges'
  }, {
    key: 'pods',
    label: 'Pods',
    value: 'pods'
  }, {
    key: 'disposables',
    label: 'Disposables',
    value: 'disposables'
  }, {
    key: 'live-resin',
    label: 'Live Resin',
    value: 'live-resin'
  }, {
    key: 'vaporizer-bundles',
    label: 'Bundles',
    value: 'vaporizer-bundles'
  }, {
    key: 'ratio',
    label: 'Ratio',
    value: 'ratio'
  }],
  Concentrate: [{
    key: 'shatter',
    label: 'Shatter',
    value: 'shatter'
  }, {
    key: 'live-resin',
    label: 'Live Resin',
    value: 'live-resin'
  }, {
    key: 'wax',
    label: 'Wax',
    value: 'wax'
  }, {
    key: 'rosin',
    label: 'Rosin',
    value: 'rosin'
  }, {
    key: 'kief',
    label: 'Kief',
    value: 'kief'
  }, {
    key: 'budder',
    label: 'Budder',
    value: 'budder'
  }, {
    key: 'crumble',
    label: 'Crumble',
    value: 'crumble'
  }, {
    key: 'rso',
    label: 'RSO',
    value: 'rso'
  }, {
    key: 'applicators',
    label: 'Applicators',
    value: 'applicators'
  }, {
    key: 'sugar',
    label: 'Sugar',
    value: 'sugar'
  }, {
    key: 'sauce',
    label: 'Sauce',
    value: 'sauce'
  }, {
    key: 'diamonds',
    label: 'Diamonds',
    value: 'diamonds'
  }, {
    key: 'isolate',
    label: 'Isolate',
    value: 'isolate'
  }, {
    key: 'infused-flower',
    label: 'Infused Flower',
    value: 'infused-flower'
  }, {
    key: 'hash',
    label: 'Hash',
    value: 'hash'
  }, {
    key: 'badder',
    label: 'Badder',
    value: 'badder'
  }, {
    key: 'concentrates-oil',
    label: 'Oil',
    value: 'concentrates-oil'
  }],
  Edible: [{
    key: 'chocolates',
    label: 'Chocolates',
    value: 'chocolates'
  }, {
    key: 'gummies',
    label: 'Gummies',
    value: 'gummies'
  }, {
    key: 'baked-goods',
    label: 'Baked Goods',
    value: 'baked-goods'
  }, {
    key: 'drinks',
    label: 'Drinks',
    value: 'drinks'
  }, {
    key: 'capsules-tablets',
    label: 'Capsules / Tablets',
    value: 'capsules-tablets'
  }, {
    key: 'chews',
    label: 'Chews',
    value: 'chews'
  }, {
    key: 'hard-candy',
    label: 'Hard Candy',
    value: 'hard-candy'
  }, {
    key: 'sublingual',
    label: 'Sublingual Products',
    value: 'sublingual'
  }, {
    key: 'frozen-treats',
    label: 'Frozen Treats',
    value: 'frozen-treats'
  }, {
    key: 'savory-snacks',
    label: 'Savory Snacks',
    value: 'savory-snacks'
  }, {
    key: 'cooking-baking',
    label: 'Cooking / Baking',
    value: 'cooking-baking'
  }, {
    key: 'dissolvables',
    label: 'Dissolvables',
    value: 'dissolvables'
  }, {
    key: 'lozenges',
    label: 'Lozenges',
    value: 'lozenges'
  }, {
    key: 'suckers',
    label: 'Suckers',
    value: 'suckers'
  }],
  Topicals: [{
    key: 'balms',
    label: 'Balms',
    value: 'balms'
  }, {
    key: 'oils',
    label: 'Topical Oils',
    value: 'oils'
  }, {
    key: 'lotions',
    label: 'Lotions',
    value: 'lotions'
  }, {
    key: 'sticks-roll-ons',
    label: 'Sticks / Roll-ons',
    value: 'sticks-roll-ons'
  }, {
    key: 'transdermal-patches',
    label: 'Transdermals',
    value: 'transdermal-patches'
  }, {
    key: 'bath-products',
    label: 'Bath Products',
    value: 'bath-products'
  }, {
    key: 'lip-balms',
    label: 'Lip Balms',
    value: 'lip-balms'
  }, {
    key: 'soaps',
    label: 'Soaps',
    value: 'soaps'
  }, {
    key: 'lubricants',
    label: 'Lubricants',
    value: 'lubricants'
  }, {
    key: 'suppositories',
    label: 'Suppositories',
    value: 'suppositories'
  }, {
    key: 'ratio',
    label: 'Ratio',
    value: 'ratio'
  }],
  Tincture: [{
    key: 'unflavored',
    label: 'Unflavored Tinctures',
    value: 'unflavored'
  }, {
    key: 'flavored',
    label: 'Flavored Tinctures',
    value: 'flavored'
  }, {
    key: 'herbal',
    label: 'Herbal Tinctures',
    value: 'herbal'
  }, {
    key: 'pet',
    label: 'Pet Tinctures',
    value: 'pet'
  }, {
    key: 'sprays',
    label: 'Sprays',
    value: 'sprays'
  }, {
    key: 'ratio',
    label: 'Ratio',
    value: 'ratio'
  }],
  Flower: [{
    key: 'shake-trim',
    label: 'Shake / Trim',
    value: 'shake-trim'
  }, {
    key: 'pre-ground',
    label: 'Pre-Ground',
    value: 'pre-ground'
  }, {
    key: 'small-buds',
    label: 'Small Buds',
    value: 'small-buds'
  }, {
    key: 'infused-bud',
    label: 'Infused Bud',
    value: 'infused-bud'
  }],
  Accessories: [{
    key: 'batteries',
    label: 'Batteries',
    value: 'batteries'
  }, {
    key: 'lighters',
    label: 'Lighters',
    value: 'lighters'
  }, {
    key: 'devices',
    label: 'Devices',
    value: 'devices'
  }, {
    key: 'papers-rolling-supplies',
    label: 'Papers / Rolling Supplies',
    value: 'papers-rolling-supplies'
  }, {
    key: 'gift-cards',
    label: 'Gift Cards',
    value: 'gift-cards'
  }, {
    key: 'grinders',
    label: 'Grinders',
    value: 'grinders'
  }, {
    key: 'glassware',
    label: 'Glassware',
    value: 'glassware'
  }, {
    key: 'trays',
    label: 'Trays',
    value: 'trays'
  }, {
    key: 'dab-tools',
    label: 'Dab Tools',
    value: 'dab-tools'
  }, {
    key: 'cleaning-solutions',
    label: 'Cleaning Solutions',
    value: 'cleaning-solutions'
  }, {
    key: 'storage-containers',
    label: 'Storage / Containers',
    value: 'storage-containers'
  }],
  Oral: [{
    key: 'sublingual',
    label: 'Sublingual',
    value: 'sublingual'
  }, {
    key: 'capsules-tablets',
    label: 'Capsules / Tablets',
    value: 'capsules-tablets'
  }],
  CBD: [],
  Seeds: [],
  Clones: [],
  Apparel: [],
  'N/A': []
});
export var WeightedSubcategories = deepFreeze(_.flatMap([].concat(_toConsumableArray(SubcategoryOptions.Flower), _toConsumableArray(SubcategoryOptions['Pre-Rolls']), _toConsumableArray(SubcategoryOptions.Vaporizers), _toConsumableArray(SubcategoryOptions.Concentrate)), function (_ref) {
  var value = _ref.value;
  return value;
}));
export var SubcategoryOptionsHash = function SubcategoryOptionsHash() {
  var hash = {};

  _.forEach(_.keys(SubcategoryOptions), function (key) {
    var subcategoriesForKey = SubcategoryOptions[key];

    _.forEach(subcategoriesForKey, function (subcategory) {
      hash[subcategory.key] = _objectSpread({
        category: key
      }, subcategory);
    });
  });

  return hash;
};
export var AllSubcategories = _.flatten(_.concat(_.values(SubcategoryOptions)));
export var CategoryPotencyRanges = deepFreeze({
  flower: [0, 50],
  'pre-rolls': [0, 50],
  vaporizers: [0, 100],
  concentrates: [0, 100],
  edibles: [0, 1000],
  topicals: [0, 1000],
  tinctures: [0, 2000],
  cbd: [0, 1000]
});
export var CategoryPotencyUnits = deepFreeze({
  flower: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.PERCENTAGE],
  'pre-rolls': POTENCY_UNITS_DISPLAY[POTENCY_UNITS.PERCENTAGE],
  vaporizers: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.PERCENTAGE],
  concentrates: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.PERCENTAGE],
  edibles: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.MILLIGRAMS],
  topicals: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.MILLIGRAMS],
  tinctures: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.MILLIGRAMS],
  cbd: POTENCY_UNITS_DISPLAY[POTENCY_UNITS.MILLIGRAMS]
});
export var CategoriesWithPotencies = ['flower', 'pre-rolls', 'vaporizers', 'concentrates', 'edibles', 'topicals', 'tinctures', 'cbd'];
/**
 * @type {Array<{
 *   key: string,
 *   label: string,
 *   value: string}
 * >}
 */

export var CategoriesForMenu = [{
  key: 'flower',
  label: 'Flower',
  value: 'Flower'
}, {
  key: 'pre-rolls',
  label: 'Pre-Rolls',
  value: 'Pre-Rolls'
}, {
  key: 'vaporizers',
  label: 'Vaporizers',
  value: 'Vaporizers'
}, {
  key: 'concentrates',
  label: 'Concentrates',
  value: 'Concentrate'
}, {
  key: 'edibles',
  label: 'Edibles',
  value: 'Edible'
}, {
  key: 'tinctures',
  label: 'Tinctures',
  value: 'Tincture'
}, {
  key: 'topicals',
  label: 'Topicals',
  value: 'Topicals'
}, {
  key: 'cbd',
  label: 'CBD',
  value: 'CBD'
}, {
  key: 'seeds',
  label: 'Seeds',
  value: 'Seeds'
}, {
  key: 'clones',
  label: 'Clones',
  value: 'Clones'
}, {
  key: 'accessories',
  label: 'Accessories',
  value: 'Accessories'
}, {
  key: 'apparel',
  label: 'Apparel',
  value: 'Apparel'
}];
export var CategoriesForFilter = [{
  key: 'all',
  label: 'All Categories',
  value: null
}, {
  key: 'flower',
  label: 'Flower',
  value: 'Flower'
}, {
  key: 'pre-rolls',
  label: 'Pre-Rolls',
  value: 'Pre-Rolls'
}, {
  key: 'vaporizers',
  label: 'Vaporizers',
  value: 'Vaporizers'
}, {
  key: 'concentrates',
  label: 'Concentrates',
  value: 'Concentrate'
}, {
  key: 'edibles',
  label: 'Edibles',
  value: 'Edible'
}, {
  key: 'topicals',
  label: 'Topicals',
  value: 'Topicals'
}, {
  key: 'tinctures',
  label: 'Tinctures',
  value: 'Tincture'
}, {
  key: 'cbd',
  label: 'CBD',
  value: 'CBD'
}, {
  key: 'seeds',
  label: 'Seeds',
  value: 'Seeds'
}, {
  key: 'clones',
  label: 'Clones',
  value: 'Clones'
}, {
  key: 'accessories',
  label: 'Accessories',
  value: 'Accessories'
}, {
  key: 'apparel',
  label: 'Apparel',
  value: 'Apparel'
}, {
  key: 'n/a',
  label: 'N/A',
  value: 'N/A'
}];
/**
 * @type {Array<{
 *   key: string,
 *   label: string,
 *   value: string | null,
 *   subcategories: Array<{key: string, label: string, value: string}>}
 * >}
 */

export var CategoryOptions = deepFreeze([{
  key: 'all',
  label: 'All Products',
  value: null,
  subcategories: []
}, {
  key: 'flower',
  label: 'Flower',
  value: 'Flower',
  subcategories: SubcategoryOptions.Flower
}, {
  key: 'pre-rolls',
  label: 'Pre-Rolls',
  value: 'Pre-Rolls',
  subcategories: SubcategoryOptions['Pre-Rolls']
}, {
  key: 'vaporizers',
  label: 'Vaporizers',
  value: 'Vaporizers',
  subcategories: SubcategoryOptions.Vaporizers
}, {
  key: 'concentrates',
  label: 'Concentrates',
  value: 'Concentrate',
  subcategories: SubcategoryOptions.Concentrate
}, {
  key: 'edibles',
  label: 'Edibles',
  value: 'Edible',
  subcategories: SubcategoryOptions.Edible
}, {
  key: 'topicals',
  label: 'Topicals',
  value: 'Topicals',
  subcategories: SubcategoryOptions.Topicals
}, {
  key: 'tinctures',
  label: 'Tinctures',
  value: 'Tincture',
  subcategories: SubcategoryOptions.Tincture
}, {
  key: 'cbd',
  label: 'CBD',
  value: 'CBD',
  subcategories: []
}, {
  key: 'seeds',
  label: 'Seeds',
  value: 'Seeds',
  subcategories: []
}, {
  key: 'clones',
  label: 'Clones',
  value: 'Clones',
  subcategories: []
}, {
  key: 'accessories',
  label: 'Accessories',
  value: 'Accessories',
  subcategories: SubcategoryOptions.Accessories
}, {
  key: 'apparel',
  label: 'Apparel',
  value: 'Apparel',
  subcategories: SubcategoryOptions.Apparel
}, {
  key: 'n/a',
  label: 'N/A',
  value: 'N/A',
  subcategories: []
}]);
export var IngestibleCategoryOption = {
  key: 'ingestibles',
  label: 'Ingestibles',
  value: 'Edible',
  subcategories: SubcategoryOptions.Edible
}; // We are using this for both FL and NY as a quick fix. - Juliana L 8/25/21

export var OralSubcategories = ['capsules-tablets', 'sublingual'];
export var CategoryDisplayNames = {};

_.forEach(CategoryOptions, function (entry) {
  CategoryDisplayNames[entry.value] = entry.label;
});

export var choiceToDisplay = {
  all: 'All Products',
  flower: 'Flower',
  'pre-rolls': 'Pre-Rolls',
  vaporizers: 'Vaporizers',
  concentrates: 'Concentrate',
  edibles: 'Edible',
  orals: 'Oral',
  topicals: 'Topicals',
  tinctures: 'Tincture',
  cbd: 'CBD',
  clones: 'Clones',
  seeds: 'Seeds',
  accessories: 'Accessories',
  apparel: 'Apparel'
};
export var MenuHeaderDisplayCategories = {
  all: 'All Products',
  flower: 'Flower',
  'pre-rolls': 'Pre-Rolls',
  vaporizers: 'Vaporizers',
  concentrates: 'Concentrates',
  edibles: 'Edibles',
  orals: 'Oral',
  topicals: 'Topicals',
  tinctures: 'Tinctures',
  cbd: 'CBD',
  clones: 'Clones',
  seeds: 'Seeds',
  accessories: 'Accessories',
  apparel: 'Apparel'
};
export var categories = _.values(choiceToDisplay);
export var ProductCategoryOptions = [{
  label: 'Flower',
  value: 'Flower'
}, {
  label: 'Pre-Rolls',
  value: 'Pre-Rolls'
}, {
  label: 'Vaporizers',
  value: 'Vaporizers'
}, {
  label: 'Concentrate',
  value: 'Concentrate'
}, {
  label: 'Edible',
  value: 'Edible'
}, {
  label: 'Topicals',
  value: 'Topicals'
}, {
  label: 'Tincture',
  value: 'Tincture'
}, {
  label: 'CBD',
  value: 'CBD'
}, {
  label: 'Clones',
  value: 'Clones'
}, {
  label: 'Seeds',
  value: 'Seeds'
}, {
  label: 'Accessories',
  value: 'Accessories'
}, {
  label: 'Apparel',
  value: 'Apparel'
}, {
  label: 'Oral',
  value: 'Oral'
}];
export var validStrainTypes = ['Indica', 'Sativa', 'Hybrid', 'High CBD', 'Indica-Hybrid', 'Sativa-Hybrid', 'THC', '1 to 1', '2 to 1', '5 to 1', '10 to 1', '20 to 1', '50 to 1'];
export var StrainTypeFilterOptions = [{
  key: 'indica',
  label: 'Indica',
  queryValue: 'indica',
  value: 'Indica'
}, {
  key: 'sativa',
  label: 'Sativa',
  queryValue: 'sativa',
  value: 'Sativa'
}, {
  key: 'hybrid',
  label: 'Hybrid',
  queryValue: 'hybrid',
  value: 'Hybrid'
}, {
  key: 'high-cbd',
  label: 'High CBD',
  queryValue: 'high-cbd',
  value: 'High CBD'
}, {
  key: 'indica-hybrid',
  label: 'Indica-Hybrid',
  queryValue: 'indica-hybrid',
  value: 'Indica-Hybrid'
}, {
  key: 'sativa-hybrid',
  label: 'Sativa-Hybrid',
  queryValue: 'sativa-hybrid',
  value: 'Sativa-Hybrid'
}, {
  key: 'thc',
  label: 'THC',
  queryValue: 'thc',
  value: 'THC'
}, {
  key: '1 to 1',
  label: '1 to 1',
  queryValue: '1 to 1',
  value: '1 to 1'
}, {
  key: '2 to 1',
  label: '2 to 1',
  queryValue: '2 to 1',
  value: '2 to 1'
}, {
  key: '5 to 1',
  label: '5 to 1',
  queryValue: '5 to 1',
  value: '5 to 1'
}, {
  key: '10 to 1',
  label: '10 to 1',
  queryValue: '10 to 1',
  value: '10 to 1'
}, {
  key: '20 to 1',
  label: '20 to 1',
  queryValue: '20 to 1',
  value: '20 to 1'
}, {
  key: '50 to 1',
  label: '50 to 1',
  queryValue: '50 to 1',
  value: '50 to 1'
}];
export var StrainTypeOptions = [{
  key: 'null',
  label: 'Select Type',
  value: ''
}, {
  key: 'indica',
  label: 'Indica',
  value: 'Indica'
}, {
  key: 'sativa',
  label: 'Sativa',
  value: 'Sativa'
}, {
  key: 'hybrid',
  label: 'Hybrid',
  value: 'Hybrid'
}, {
  key: 'high-cbd',
  label: 'High CBD',
  value: 'High CBD'
}, {
  key: 'indica-hybrid',
  label: 'Indica-Hybrid',
  value: 'Indica-Hybrid'
}, {
  key: 'sativa-hybrid',
  label: 'Sativa-Hybrid',
  value: 'Sativa-Hybrid'
}, {
  key: 'thc',
  label: 'THC',
  value: 'THC'
}, {
  key: '1 to 1',
  label: '1 to 1',
  value: '1 to 1'
}, {
  key: '2 to 1',
  label: '2 to 1',
  value: '2 to 1'
}, {
  key: '5 to 1',
  label: '5 to 1',
  value: '5 to 1'
}, {
  key: '10 to 1',
  label: '10 to 1',
  value: '10 to 1'
}, {
  key: '20 to 1',
  label: '20 to 1',
  value: '20 to 1'
}, {
  key: '50 to 1',
  label: '50 to 1',
  value: '50 to 1'
}, {
  key: 'n/a',
  label: 'N/A',
  value: 'N/A'
}];
export var StrainTypeOrder = {
  Indica: 0,
  Sativa: 1,
  Hybrid: 2,
  'High CBD': 3,
  'Indica-Hybrid': 4,
  'Sativa-Hybrid': 5,
  THC: 6,
  '1 to 1': 7,
  '2 to 1': 8,
  '5 to 1': 9,
  '10 to 1': 10,
  '20 to 1': 11,
  '50 to 1': 12,
  'N/A': 13,
  '': 14
};
export var AdminMenuOrderOptions = [{
  key: 'alpha',
  label: 'Product Name',
  value: 'alpha'
}, {
  key: 'custom',
  label: 'Custom',
  value: 'custom'
}, {
  key: 'brand',
  label: 'Brand',
  value: 'brand'
}, {
  key: 'priceLowToHigh',
  label: 'Price - Low to High',
  value: 'priceLowToHigh'
}, {
  key: 'priceHighToLow',
  label: 'Price - High to Low',
  value: 'priceHighToLow'
}];
export var EmptyPotencies = ['', ' ', null];
export var libraryOverrideKeys = ['brandId', 'CBD', 'CBDContent', 'Image', 'THC', 'THCContent', 'type', 'subcategory', 'Name'];
export var MILLIGRAMS = 'MILLIGRAMS';
export var MILLILITERS = 'MILLILITERS';
export var GRAMS = 'GRAMS';
export var MILLILITERS_PER_OZ = 29.5735;
export var GRAM_TO_OZ = 28;
export var MG_TO_G = 1000;
export var MG_WEIGHT_REGEX = /\d*\.?\d+\s{0,1}(mg)/i;
export var GRAM_WEIGHT_REGEX = /\d*\.?\d+\s{0,1}[g]/i;
export var CONC_WEIGHT_REGEX = /\d*\.?\d+\s{0,1}(gC)/i;
export var PARTIAL_OZ_REGEX = /\d{1}\/\d{1,2}(oz)/i;
export var OZ_WEIGHT_REGEX = /\d*\.?\d+\s{0,1}(oz)/i;
export var MULTIPLIER_REGEX = /^(\d+)\s*x\s*(.+)$/;
export var integrationProductFields = ['Product Name', 'Product Description', 'Product Category', 'Brand', 'Image', 'Size / Purchase Option', 'Weight', 'Unit of Measure', 'Price', 'Quantity', 'Strain Type', 'THC Potency', 'THC Potency Unit (% or mg)', 'CBD Potency', 'CBD Potency Unit (% or mg)', 'Standard Equivalent Weight', 'Standard Equivalent Unit', 'Terpenes'];
/**
 * @type {Object.<string, string>}
 */

export var defaultCategoryPhotos = {
  Flower: 'https://images.dutchie.com/category-stock-photos/flower/flower-1.png',
  'Pre-Rolls': 'https://images.dutchie.com/category-stock-photos/pre-rolls/pre-rolls-1.png',
  Vaporizers: 'https://images.dutchie.com/category-stock-photos/vaporizers/vaporizers-1.png',
  Concentrate: 'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-1.png',
  Edible: 'https://images.dutchie.com/category-stock-photos/edibles/edibles-1.png',
  Oral: 'http://images.dutchie.com/category-stock-photos/orals/orals-1.png',
  Tincture: 'https://images.dutchie.com/category-stock-photos/tinctures/tinctures-1.png',
  Topicals: 'https://images.dutchie.com/category-stock-photos/topicals/topicals-1.png',
  CBD: 'https://images.dutchie.com/category-stock-photos/cbd/cbd.png',
  Seeds: 'https://images.dutchie.com/category-stock-photos/seeds/seeds.png',
  Clones: 'https://images.dutchie.com/category-stock-photos/clones/clones.png',
  Accessories: 'https://images.dutchie.com/category-stock-photos/accessories/accessories.png',
  Apparel: 'https://images.dutchie.com/category-stock-photos/apparel/apparel.png'
};
export var MAX_OPTION_TEXT_LENGTH = 5;
export var SCORE_DESCRIPTION_LENGTH_MINIMUM = 95;
/* eslint-disable max-len */

export var thcDescription = {
  head: "THC",
  body: "is the primary cannabinoid responsible for the psychoactive effects associated with cannabis. The amount of THC in a product can vary widely based on the method of consumption and the strain at the source of that product. The high that is produced is often enhanced by the \u201Centourage effect\u201D  which is a combination of multiple cannabinoids in conjunction with various terpenes and individual body chemistry."
};
export var cbdDescription = {
  head: "CBD",
  body: "is the second most prevalent cannabinoid and is primarily produced by hemp plants and at lower amounts in cannabis. CBD has soared in popularity due to its non-psychoactive effects. Most users seek CBD for its medicinal properties since it was the first cannabinoid to be approved by the FDA. Its healing properties include an ability to help you relax, reduce irritability and ease restlessness."
};
export var tacDescription = {
  head: "TAC or Total active cannabinoids",
  body: "represents the total amount of active cannabinoids at the time of lab testing. This figure includes all of the active compounds that the product holds."
};
export var cannabinoidSectionCopy = "Cannabinoids are naturally occurring chemical compounds that are found in cannabis and provide consumers with a wide range of effects. THC and CBD are examples of some of the most commonly known cannabinoids.";
/* eslint-enable max-len */
// to be used for clearing out conditions/rewards

export var UNUSED_BOGO_FIELDS = ['__discountType', 'brands', 'brandDescription', 'description', 'dispensaryProducts', 'categories', 'DispensaryID', 'enterpriseProductId', 'id', 'Image', 'label', 'medicalOnly', 'medicalPrices', 'Name', 'name', 'Options', 'options', 'products', 'productDiscounts', 'Prices', 'recOnly', 'recPrices', 'rowType', 'specialRestrictions', 'Status', 'subcategories', 'subcategory', 'type', 'updatedAt']; // used for clearing out anything on the special itself

export var UNUSED_BOGO_SPECIAL_FIELDS = ['__discountType', 'brands', 'brandDescription', 'description', 'dispensaryProducts', 'categories', 'DispensaryID', 'id', 'Image', 'label', 'medicalOnly', 'medicalPrices', 'Name', 'Options', 'options', 'products', 'productDiscounts', 'Prices', 'recOnly', 'recPrices', 'rowType', 'specialRestrictions', 'Status', 'subcategories', 'subcategory', 'type', 'updatedAt']; // TODO: get rid of the original UNUSED_BOGO_FIELDS and UNUSED_BOGO_SPECIAL_FIELDS once v3.5 is released GA

export var UNUSED_BOGO_FIELDS_V35 = [].concat(UNUSED_BOGO_FIELDS, ['dollarDiscount', '__key', 'parentCategory', 'percentDiscount', 'product', 'targetPrice', '__type']);
export var MISSING_PRODUCT_BRAND_PLACEHOLDER_ID = 'NO_BRAND_ID';