import React from 'react';
import { useRouter } from 'next/router';
import { AppRoutes } from 'src/utils/constants';
import { Layout } from 'src/components/layout';

type LayoutRendererProps = {
  children: React.ReactNode;
};

function LayoutRenderer({ children }: LayoutRendererProps): JSX.Element {
  const router = useRouter();
  const routesWithoutLayout = [
    AppRoutes.forgotPassword(),
    AppRoutes.invitationPassword(),
    AppRoutes.login(),
    AppRoutes.resetPassword(),
    AppRoutes.terms(),
  ];

  const pathDoesNotHaveLayout = routesWithoutLayout.includes(router.pathname);

  // Do not render the layout
  if (pathDoesNotHaveLayout) {
    return <>{children}</>;
  }

  // Add the layout if it's not in the blacklist
  return <Layout>{children}</Layout>;
}

export default LayoutRenderer;
