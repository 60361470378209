import React from 'react';
import styled from 'styled-components';
import { ModalClose } from './close';
import { ResetButton } from '../components/forms/inputs';
import { colors } from '../styles';

type ContainerStyleProps = {
  textButton?: boolean;
  centered?: boolean;
  height?: string;
  children?: React.ReactNode;
};

const Container = styled((props: ContainerStyleProps) => <div {...props} />)`
  display: flex;
  z-index: 5;
  background-color: #ecf0f3;
  border-color: #d1d5da;
  height: ${(props) => (props.height ? props.height : '60px')};
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  justify-content: ${(props) => {
    if (props.centered) {
      return 'center';
    }
    if (props.textButton) {
      return 'space-between';
    }
    return 'flex-start';
  }};
  padding: ${(props) => {
    if (props.textButton) {
      return '4px 60px 0 33px';
    }
    return '1px 33px 0 33px';
  }};
`;

type HeaderStyleProps = {
  icon?: string | undefined;
  children?: React.ReactNode;
  centered?: boolean;
};

const Header = styled(({ children, ...props }: HeaderStyleProps) => <h2 {...props}>{children}</h2>)`
  color: #6d747b;
  font-weight: bold;
  font-size: 16px;
  ${(props) =>
    props.icon &&
    `
    margin-left: 12px;
  `}

  ${(props) =>
    props.centered &&
    `
    font-size: 20px;
  `}
`;

const Close = styled(ModalClose)`
  top: 12px;
  right: 15px;
`;

const TextButton = styled((props) => <ResetButton color={colors.blue} {...props} />)`
  &:hover {
    text-decoration: underline;
  }
`;

type ModalPrimaryHeaderProps = ContainerStyleProps &
  HeaderStyleProps & {
    copy: JSX.Element | string;
    textButtonFunc?: boolean;
    close: VoidFunction;
    icon?: boolean | string;
  };

export function ModalPrimaryHeader(
  /** @type {textButton: string | boolean | undefined} */ {
    icon,
    copy,
    textButton = false,
    textButtonFunc = false,
    close,
    centered = false,
    ...props
  }: ModalPrimaryHeaderProps
): JSX.Element {
  return (
    <Container textButton={textButton} centered={centered} {...props}>
      {icon && <img width='24px' src={icon} />}
      <Header centered={centered} icon={icon}>
        {copy}
      </Header>
      {textButton && <TextButton onClick={textButtonFunc}>{textButton}</TextButton>}
      <Close onClick={close} />
    </Container>
  );
}

export default ModalPrimaryHeader;
