import { observable, action, makeAutoObservable } from 'mobx';
import { RootState } from 'src/state/root';
import { ConfirmModalProps } from 'src/components/modals/confirm-modal';

type ErnieQueueType = 'danger' | 'error' | 'info' | 'success';
type ErnieQueueItem = {
  msg: string;
  type: ErnieQueueType;
  timeout: number;
};

export enum ModalTypes {
  showSignup = 'showSignup',
  confirmation = 'confirmation',
}

export class UIState {
  private readonly rootState: RootState;
  @observable ernieQueue: ErnieQueueItem[] = observable.array([]);
  @observable modalStates: Record<ModalTypes, boolean> = makeAutoObservable<Record<ModalTypes, boolean>>({
    [ModalTypes.showSignup]: false,
    [ModalTypes.confirmation]: false,
  });
  @observable confirmModalProps: ConfirmModalProps = makeAutoObservable<ConfirmModalProps>({
    modalTitle: 'Confirm Action',
    confirmActionText: 'Proceed',
    cancelActionText: 'Cancel',
    modalCopy: 'Are you sure you want to perform this action?',
    onConfirm: () => undefined,
    onCancel: () => undefined,
  });

  constructor(rootState: RootState) {
    this.rootState = rootState;
  }

  @action toggleModal = (name: ModalTypes): void => {
    this.modalStates[name] = !this.modalStates[name];
  };

  @action showErnie = (
    msg = 'Something went wrong. Please contact support.',
    type: ErnieQueueType = 'danger',
    timeout?: number
  ): void => {
    // Explanation:
    //    Assuming 200 wpm; Show duration of 50ms x # of characters, about 1 character every 50 milliseconds.
    //    Apply a 2250ms minimum to allow the user time to congitively context-switch even for short notices
    //    Apply a 7000ms maximum; anything that requires more should probably be reconsidered from auto-dismiss.
    const time = Math.min(Math.max(msg.length * 50, 2250), 7000);
    this.ernieQueue.push({ msg, type, timeout: timeout ?? time });
  };

  @action openConfirmModal = (values: Partial<ConfirmModalProps>): void => {
    this.confirmModalProps = {
      ...this.confirmModalProps,
      ...values,
    };
    this.toggleModal(ModalTypes.confirmation);
  };
}
