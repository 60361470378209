import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AppRoutes } from 'src/utils/constants';
import { observer } from 'mobx-react';
import { useStores } from 'src/state';

import dynamic from 'next/dynamic';

const BlankScreen = dynamic(() => import('./_blank_screen'), { ssr: false });
const MockScreen = dynamic(() => import('./_mock_screen'), { ssr: false });

type ProtectedRouteProps = {
  children: React.ReactNode;
};

const denyLoggedInUserAccess = [AppRoutes.forgotPassword(), AppRoutes.login(), AppRoutes.invitationPassword()];
const allowLoggedInUserAccess = [AppRoutes.resetPassword(), AppRoutes.terms()];
const unprotectedRoutes = denyLoggedInUserAccess.concat(allowLoggedInUserAccess);

function ProtectedRoute({ children }: ProtectedRouteProps): JSX.Element {
  const router = useRouter();
  const { user } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const { pathname, asPath } = router;
  const hasLoggedInUser = user.exists;
  const hasUserToken = user.token.length > 0;
  const hasUserInfo = hasUserToken || hasLoggedInUser;
  const missingLoggedInUserInfo = hasUserToken && !hasLoggedInUser;
  const isProtectedRoute = !unprotectedRoutes.includes(pathname);
  const needsLoggedInUser = !hasLoggedInUser && isProtectedRoute;
  const redirectLoggedInUser = hasLoggedInUser && denyLoggedInUserAccess.includes(pathname);
  const showBlankScreen = isLoading && !hasUserInfo && isProtectedRoute;
  const showMockScreen = (isLoading && missingLoggedInUserInfo) || redirectLoggedInUser;

  // nextjs router.query does not populate in time, so we need to manually parse the
  // existing query params to preserve them on login redirect
  // https://nextjs.org/docs/routing/dynamic-routes#caveats

  // BUT router.query contains the interpolated href params,
  // so we need to include that as well :sigh:
  const urlParams = asPath.includes('?') ? Object.fromEntries(new URLSearchParams(asPath.split('?').pop())) : {};

  const redirectToLogin = async (): Promise<void> => {
    if (isProtectedRoute) {
      await router.push({
        pathname: AppRoutes.login(),
        query: {
          ...router.query,
          ...urlParams,
          ...(pathname.length > 1 && !urlParams.redirect ? { redirect: pathname } : {}),
        },
      });
    } else {
      await router.push(AppRoutes.login());
    }

    setIsLoading(false);
  };

  const fetchUser = async (): Promise<void> => {
    const success = await user.fetch();
    if (success) {
      await router.push({
        pathname,
        query: { ...router.query, ...urlParams },
      });

      setIsLoading(false);
      return;
    }

    user.token = '';
    await redirectToLogin();
  };

  useEffect(() => {
    // Redirect to home if the user tries to navigate to an unprotected page while they're already logged in
    if (redirectLoggedInUser) {
      void router.push(AppRoutes.home());
      return;
    }

    // We have some credentials for our user but no user object
    if (missingLoggedInUserInfo) {
      void fetchUser();
      return;
    }

    // User or credentials simply do not exist and page is protected
    if (needsLoggedInUser) {
      void redirectToLogin();
    }

    // Note: We're explicit about what we allow as a dependency in this case,
    // A few of these properties would be busted by the logic above in specific cases; so they're not included.
    // e.g next.js's `router` object that returns a new object every render. Variables that are computed from the
    // pathname of the route also are excluded due to the redirect in useEffect busting these dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingLoggedInUserInfo, redirectLoggedInUser, needsLoggedInUser]);

  // Show blank screen if there is no user token and it's a protected route
  if (showBlankScreen) {
    return <BlankScreen />;
  }

  // Show mock screen if there is a user token and attempting to fetch user
  if (showMockScreen) {
    return <MockScreen />;
  }

  return <>{children}</>;
}

export default observer(ProtectedRoute);
