import _ from 'lodash';
import { GqlMeAdminQuery } from 'types/graphql';

type User = GqlMeAdminQuery['meAdmin'];

type HasNameDatums = {
  firstName: string | undefined;
  lastName: string | undefined;
  fullName: string | undefined;
};

export const formatName = (profile: HasNameDatums): string => {
  const firstName = _.get(profile, 'firstName');
  const lastName = _.get(profile, 'lastName');
  const fullName = _.get(profile, 'fullName');
  return fullName ?? (!(_.isNil(firstName) && _.isNil(lastName)) ? `${firstName ?? ''} ${lastName ?? ''}` : 'unknown');
};

export const formatNameWithLastInitial = (profile: HasNameDatums): string => {
  const formattedName = formatName(profile);
  const [firstName, lastName] = _.split(formattedName, ' ');

  // fullName could possibly be one name with no spaces. formatName can also just return 'unknown';
  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName[0]}.`;
};

export const isConsumerUser = (user?: User): boolean =>
  Boolean(user?.profile.type === 'user' || _.isNil(user?.profile.type));

export const isSuperAdmin = (user?: User): boolean => user?.profile.type === 'superAdmin';

export const isDispensaryUser = (user?: User): boolean => user?.profile.type === 'dispensaryUser';
