import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'src/shared/styles';
import { position } from 'styled-system';

export const ElementContainer = styled((props) => <div {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  ${position}
  @media ${mediaQueries.largePhone} {
    flex-direction: column;
    align-items: flex-start;
    min-height: 80px;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  color: #707478;
  font-size: 13px;
  width: 125px;
  flex-shrink: 0;
  text-align: right;
  padding-right: 35px;
  @media ${mediaQueries.largePhone} {
    text-align: left;
    margin: 10px 0px 3px 0px;
  }
`;
